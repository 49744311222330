import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import { Select } from '@/components/form/Select';
import { Input } from '@/components/form/Input';
import { Container, Row } from './InformationsStep.style';
import { BadgeReaderDriver } from '@/services/innovorder/badgeReader/badgeReader.type';

type InformationsStepProps = {};

const driversOptions = (intl: IntlShape) => [
    { label: computeText(intl, 'badgeReader.driver.keyboard'), value: BadgeReaderDriver.Keyboard },
    { label: computeText(intl, 'badgeReader.driver.serial'), value: BadgeReaderDriver.Serial },
    { label: computeText(intl, 'badgeReader.driver.toqla'), value: BadgeReaderDriver.Toqla },
];

export const InformationsStep: React.FunctionComponent<InformationsStepProps> = () => {
    const intl = useIntl();

    return (
        <Container>
            <Row>
                <Input
                    name="name"
                    rules={{ required: computeText(intl, 'field.required') }}
                    placeholder="badgeReader.form.name"
                    labelId="badgeReader.form.name"
                />
            </Row>
            <Row>
                <Input
                    name="deviceId"
                    rules={{ required: computeText(intl, 'field.required') }}
                    placeholder="badgeReader.form.deviceId"
                    labelId="badgeReader.form.deviceId"
                />
            </Row>
            <Row>
                <Input
                    name="device_uuid"
                    rules={{ required: computeText(intl, 'field.required') }}
                    placeholder="badgeReader.form.deviceUuid"
                    labelId="badgeReader.form.deviceUuid"
                />
            </Row>
            <Row>
                <Select
                    name="driver"
                    rules={{ required: computeText(intl, 'field.required') }}
                    options={driversOptions(intl)}
                    placeholder="badgeReader.form.driver"
                    labelId="badgeReader.form.driver"
                />
            </Row>
        </Container>
    );
};
