import { ApiTagTypes, innovorderApi } from '../index';
import { ApiRequest, ApiResponse } from '../request.types';
import {
    GetBrandPayload,
    Brand,
    GetBrandGroups,
    ActiveStudentEntities,
    BrandOptions,
    UpdateBrandOptionsPayload,
    GetBrandOptionsResponse,
    DomainNames,
    PaymentConfiguration,
    GetBrandsListResponse,
    GetBrandListV2Payload,
    ToggleBrandFavoritePayload,
    CreateBrandResponse,
    CreateBrandPayload,
} from './brand.type';
import { Grant } from '../grant/grant.type';
import { EntranceFee } from '../entranceFee/entranceFee.type';
import { Restaurant } from '../restaurant/restaurant.type';

const brandApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getBrand: build.query<Brand, ApiRequest<GetBrandPayload>>({
            query: ({ brandId, token }) => ({
                url: `/brands/${brandId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [ApiTagTypes.Brand],
            transformResponse: (response: ApiResponse<Brand>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getBrandGrants: build.query<Grant[], ApiRequest<GetBrandPayload>>({
            query: ({ brandId, token }) => ({
                url: `/brands/${brandId}/grants`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [ApiTagTypes.Grant],
            transformResponse: (response: ApiResponse<Grant[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getBrandEntranceFees: build.query<EntranceFee[], ApiRequest<GetBrandPayload>>({
            query: ({ brandId, token }) => ({
                url: `/brands/${brandId}/entrance_fees`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            providesTags: [ApiTagTypes.EntranceFee],
            transformResponse: (response: ApiResponse<EntranceFee[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getBrandRestaurants: build.query<Restaurant[], ApiRequest<{ brandId: number }>>({
            query: ({ brandId, token }) => ({
                url: `/brands/${brandId}/restaurants`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<Restaurant[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getBrandGroups: build.query<string[], ApiRequest<GetBrandGroups>>({
            query: ({ brandId, token }) => ({
                url: `/brands/${brandId}/groups`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<string[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getGroups: build.mutation<string[], ApiRequest<GetBrandGroups>>({
            query: ({ brandId, token }) => ({
                url: `/brands/${brandId}/groups`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<string[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getRestaurantsForBrand: build.mutation<Restaurant[], ApiRequest<GetBrandGroups>>({
            query: ({ brandId, token }) => ({
                url: `/brands/${brandId}/restaurants`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<Restaurant[]>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getActiveStudentsEntities: build.query<ActiveStudentEntities, ApiRequest<{ brandId: number }>>({
            query: ({ brandId, token }) => ({
                url: `/brands/${brandId}/active_students_entities`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<ActiveStudentEntities>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getWhitelistedCustomerEmailDomainNames: build.query<DomainNames, ApiRequest<{ brandId: number }>>({
            query: ({ brandId, token }) => ({
                url: `/brands/${brandId}/whitelisted_customer_email_domain_names`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<DomainNames>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getBrandPaymentConfiguration: build.query<PaymentConfiguration, ApiRequest<{ brandId: number }>>({
            query: ({ brandId, token }) => ({
                url: `/brands/${brandId}/payment_configuration`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<PaymentConfiguration>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        getBrandOptions: build.query<GetBrandOptionsResponse, ApiRequest<{ brandId: number }>>({
            query: ({ brandId, token }) => ({
                url: `/brands/${brandId}/options`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<GetBrandOptionsResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        updateBrandOptions: build.mutation<BrandOptions, ApiRequest<UpdateBrandOptionsPayload>>({
            query: ({ brandId, token, options }) => ({
                url: `/brands/${brandId}/options`,
                method: 'PUT',
                headers: {
                    authorization: `Bearer ${token}`,
                },
                body: {
                    brandId,
                    options,
                },
            }),
            transformResponse: (response: ApiResponse<BrandOptions>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),

        getBrandsListV2: build.query<GetBrandsListResponse, ApiRequest<GetBrandListV2Payload>>({
            query: (data) => {
                const { offset, limit, search, filter, sortBy, token } = data;
                return {
                    url: `brands/v2/`,
                    method: 'GET',
                    params: { offset, limit, search, ...filter, sortBy: JSON.stringify(sortBy) },
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                };
            },
            transformResponse: (response: ApiResponse<GetBrandsListResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
            providesTags: [ApiTagTypes.BrandsList],
        }),

        toggleBrandFavorites: build.mutation<
            ApiResponse<Record<string, never>>,
            ApiRequest<ToggleBrandFavoritePayload>
        >({
            query: ({ brandId, token, status }) => ({
                url: `brands/favorites`,
                method: 'POST',
                headers: {
                    authorization: `Bearer ${token}`,
                },
                body: { brandId, status },
            }),
            invalidatesTags: [ApiTagTypes.BrandsList],
            onQueryStarted: async ({ brandId, latestFetchParams }, { dispatch, queryFulfilled }) => {
                if (latestFetchParams === null) return;
                const patchResult = dispatch(
                    brandApi.util.updateQueryData('getBrandsListV2', latestFetchParams, (brandsList) => {
                        const indexToBeChanged = brandsList.brands.map((el) => el.brandId).indexOf(brandId);
                        brandsList.brands[indexToBeChanged].isFavorite =
                            !brandsList.brands[indexToBeChanged].isFavorite;
                    }),
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        createBrand: build.mutation<CreateBrandResponse, ApiRequest<CreateBrandPayload>>({
            query: ({ token, ...data }) => ({
                url: `brands`,
                method: 'POST',
                headers: {
                    authorization: `Bearer ${token}`,
                },
                body: {
                    name: data.name,
                    channels: data.channels,
                    locale: data.locale,
                    currency: data.currency,
                    user: {
                        firstName: data.firstName,
                        lastName: data.lastName,
                        phone: data.phone,
                        email: data.email,
                        password: data.password,
                        confirmPassword: data.confirmPassword,
                    },
                },
            }),
            transformResponse: (response: ApiResponse<CreateBrandResponse>) => response.data,
            invalidatesTags: [ApiTagTypes.BrandsList],
        }),
    }),
});

export const {
    useGetActiveStudentsEntitiesQuery,
    useLazyGetActiveStudentsEntitiesQuery,
    useGetWhitelistedCustomerEmailDomainNamesQuery,
    useGetBrandQuery,
    useGetBrandEntranceFeesQuery,
    useGetBrandGrantsQuery,
    useGetBrandGroupsQuery,
    useGetBrandOptionsQuery,
    useGetBrandRestaurantsQuery,
    useGetGroupsMutation,
    useGetRestaurantsForBrandMutation,
    useUpdateBrandOptionsMutation,
    useLazyGetBrandPaymentConfigurationQuery,
    useLazyGetBrandsListV2Query,
    useToggleBrandFavoritesMutation,
    useCreateBrandMutation,
    useLazyGetBrandGrantsQuery,
    useLazyGetBrandEntranceFeesQuery,
} = brandApi;
