import { IntlShape } from 'react-intl';
import { Column } from 'react-table';
import { TableCell, TableRow } from '@/components/atoms/Table/Table.type';
import { computeText } from '@/locales/utils';
import {
    GuestGroupWithGuestsCount,
    PricingRule,
    RelatedGuestsCount,
} from '@/services/innovorder/guestGroup/guestGroup.type';

export const getGroupsTableColumnsVM = (intl: IntlShape): readonly Column<TableRow>[] => [
    {
        Header: computeText(intl, 'group.name'),
        accessor: 'name',
    },
    {
        Header: computeText(intl, 'group.pricingRules'),
        accessor: 'pricingRules',
    },
    {
        Header: computeText(intl, 'group.guestsCount'),
        accessor: 'guestsCount',
    },
    {
        Header: computeText(intl, 'group.action'),
        accessor: 'action',
        width: '1%',
        disableSortBy: true,
    },
];

const parsePricingRulesNames = (pricingRules: PricingRule[]) => {
    return pricingRules.map((pricingRule) => pricingRule.code).join('; ');
};

const parseGroupNameField = (groupName: string): TableCell => {
    return {
        type: 'string',
        value: {
            text: groupName,
        },
    };
};

const parseGroupPricingRulesField = (pricingRules: PricingRule[]): TableCell => {
    return {
        type: 'string',
        value: {
            text: parsePricingRulesNames(pricingRules),
        },
    };
};

const parseGuestsCountField = (guestsCount: number, intl: IntlShape): TableCell => {
    return {
        type: 'string',
        value: {
            text: `${guestsCount} ${computeText(intl, 'group.guestsCountLabel')}`,
        },
    };
};

const parseGroupNameAction = (
    groupId: number,
    intl: IntlShape,
    onDelete: (id: number) => void,
    onUpdateClick: (id: number) => void,
): TableCell => {
    return {
        type: 'actionList',
        value: [
            {
                children: computeText(intl, 'button.edit'),
                buttonType: 'primary',
                onClick: () => onUpdateClick(groupId),
            },
            {
                children: computeText(intl, 'button.delete'),
                buttonType: 'outlinedPrimary',
                onClick: () => onDelete(groupId),
            },
        ],
    };
};

const mapGuestGroupToTableRow = (
    guestGroup: GuestGroupWithGuestsCount,
    intl: IntlShape,
    onDelete: (id: number) => void,
    onUpdateClick: (id: number) => void,
): TableRow => {
    return {
        name: parseGroupNameField(guestGroup.name),
        pricingRules: parseGroupPricingRulesField(guestGroup.pricingRules),
        guestsCount: parseGuestsCountField(guestGroup.guestsCount, intl),
        action: parseGroupNameAction(guestGroup.guestGroupId, intl, onDelete, onUpdateClick),
    };
};

export const getGroupsTableRowsVM = (
    intl: IntlShape,
    onDelete: (id: number) => void,
    onUpdateClick: (id: number) => void,
    guestGroups?: GuestGroupWithGuestsCount[],
): TableRow[] => {
    if (!guestGroups) {
        return [];
    }
    return guestGroups.map((guestGroup) => mapGuestGroupToTableRow(guestGroup, intl, onDelete, onUpdateClick));
};

export const canGroupBeDeleted = (relatedGuestsData?: RelatedGuestsCount): boolean =>
    !!relatedGuestsData && relatedGuestsData.count <= 0;
