import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/redux/store';
import { castLDFlags } from './utils';

export const launchDarklySliceName = 'launchDarkly';

export interface LaunchDarklyState {
    isInitialized: boolean;
    useAssociatedPricingRules: boolean;
    useModuleMarketplaceInUbereatsConfig: boolean;
    useNewEditMenuPages: boolean;
    useOpsBulkTools: boolean;
    useNewPaymentMethodsPage: boolean;
    useNewCustomerPage: boolean;
    useIoAnalyticsMobileDashboard: boolean;
    useNewGuestJourneyDesign: boolean;
    useCustomerFormAutoGenerateEmailAndPassword: boolean;
    useNewGuestJourneyDesignWithSectionAndSubSection: boolean;
    useGenerateOrdersReportAsynchronously: boolean;
    useEwalletExport: boolean;
    usePosMenuFastImport: boolean;
    useReportsV2: boolean;
    useMigratedPaygreenV3: boolean;
    useIopayKycInterface: boolean;
    useBillingSpace: boolean;
    useCrossSelling: boolean;
    useCollectiveCateringSalesSummary: boolean;
    useMenuCrossSelling: boolean;
    useOptionIncludeDepositsInTurnover: boolean;
    useAiMenuScan: boolean;
    useOpsWeekRoleCheckout: boolean;
    useOpsWeekTableLayout: boolean;
    useShift: boolean;
    useNewChannelsPage: boolean;
    useNewBrandUserPermissionPage: boolean;
    useNewUsersPage: boolean;
    useNewBrandUserPermissionForPasswordChange: boolean;
    useNewBrandsPage: boolean;
    useNewAnalyticsCommercialRealtimeBoard: boolean;
    useBadgeReader: boolean;
}

const initialState: LaunchDarklyState = {
    usePosMenuFastImport: false,
    useAssociatedPricingRules: false,
    useModuleMarketplaceInUbereatsConfig: false,
    useNewEditMenuPages: false,
    useOpsBulkTools: false,
    useNewPaymentMethodsPage: true,
    useNewCustomerPage: false,
    useIoAnalyticsMobileDashboard: false,
    useNewGuestJourneyDesign: false,
    useCustomerFormAutoGenerateEmailAndPassword: false,
    useNewGuestJourneyDesignWithSectionAndSubSection: false,
    useGenerateOrdersReportAsynchronously: false,
    isInitialized: false,
    useEwalletExport: false,
    useReportsV2: false,
    useMigratedPaygreenV3: false,
    useIopayKycInterface: false,
    useBillingSpace: false,
    useCrossSelling: false,
    useCollectiveCateringSalesSummary: false,
    useMenuCrossSelling: false,
    useOptionIncludeDepositsInTurnover: false,
    useAiMenuScan: false,
    useOpsWeekRoleCheckout: false,
    useOpsWeekTableLayout: false,
    useShift: false,
    useNewChannelsPage: false,
    useNewBrandUserPermissionPage: false,
    useNewUsersPage: false,
    useNewBrandUserPermissionForPasswordChange: false,
    useNewBrandsPage: false,
    useNewAnalyticsCommercialRealtimeBoard: false,
    useBadgeReader: false,
};

const launchDarklySlice = createSlice({
    name: launchDarklySliceName,
    initialState,
    reducers: {
        updateLDFlags: (_, action: PayloadAction<Record<string, boolean>>) => castLDFlags(action.payload),
    },
});

export const getLaunchDarklyState = (state: RootState): LaunchDarklyState => state.launchDarkly;
export const getUseAssociatedPricingRules = (state: RootState): boolean =>
    getLaunchDarklyState(state).useAssociatedPricingRules;
export const getUseModuleMarketplaceInUbereatsConfig = (state: RootState): boolean =>
    getLaunchDarklyState(state).useModuleMarketplaceInUbereatsConfig;
export const getUseOpsBulkTools = (state: RootState): boolean => getLaunchDarklyState(state).useOpsBulkTools;
export const getUseNewGuestJourneyDesign = (state: RootState): boolean =>
    getLaunchDarklyState(state).useNewGuestJourneyDesign;
export const getUseIoAnalyticsMobileDashboard = (state: RootState): boolean =>
    getLaunchDarklyState(state).useIoAnalyticsMobileDashboard;

export const getUseCustomerFormAutoGenerateEmailAndPassword = (state: RootState): boolean =>
    getLaunchDarklyState(state).useCustomerFormAutoGenerateEmailAndPassword;

export const getUseNewGuestJourneyDesignWithSectionAndSubSection = (state: RootState): boolean =>
    getLaunchDarklyState(state).useNewGuestJourneyDesignWithSectionAndSubSection;
export const getUseGenerateOrdersReportAsynchronously = (state: RootState): boolean =>
    getLaunchDarklyState(state).useGenerateOrdersReportAsynchronously;
export const getUseNewPaymentMethodsPage = (state: RootState): boolean =>
    getLaunchDarklyState(state).useNewPaymentMethodsPage;
export const getUseNewCustomerPage = (state: RootState): boolean => getLaunchDarklyState(state).useNewCustomerPage;

export const getUseEwalletExport = (state: RootState): boolean => getLaunchDarklyState(state).useEwalletExport;

export const getUseNewEditMenuPage = (state: RootState): boolean => getLaunchDarklyState(state).useNewEditMenuPages;

export const getUsePosMenuFastImport = (state: RootState): boolean => getLaunchDarklyState(state).usePosMenuFastImport;
export const getUseReportsV2 = (state: RootState): boolean => getLaunchDarklyState(state).useReportsV2;
export const getUseMigratedPaygreenV3 = (state: RootState): boolean =>
    getLaunchDarklyState(state).useMigratedPaygreenV3;
export const getUseIopayKycInterface = (state: RootState): boolean => getLaunchDarklyState(state).useIopayKycInterface;
export const getUseBillingSpace = (state: RootState): boolean => getLaunchDarklyState(state).useBillingSpace;
export const getUseCrossSelling = (state: RootState): boolean => getLaunchDarklyState(state).useCrossSelling;
export const getUseCollectiveCateringSalesSummary = (state: RootState): boolean =>
    getLaunchDarklyState(state).useCollectiveCateringSalesSummary;

export const getUseMenuCrossSelling = (state: RootState): boolean => getLaunchDarklyState(state).useMenuCrossSelling;
export const getUseOptionIncludeDepositsInTurnover = (state: RootState): boolean =>
    getLaunchDarklyState(state).useOptionIncludeDepositsInTurnover;
export const getUseAiMenuScan = (state: RootState): boolean => getLaunchDarklyState(state).useAiMenuScan;
export const getUseOpsWeekRoleCheckout = (state: RootState): boolean =>
    getLaunchDarklyState(state).useOpsWeekRoleCheckout;
export const getUseOpsWeekTableLayout = (state: RootState): boolean =>
    getLaunchDarklyState(state).useOpsWeekTableLayout;
export const getUseShift = (state: RootState): boolean => getLaunchDarklyState(state).useShift;
export const getUseNewUsersPage = (state: RootState): boolean => getLaunchDarklyState(state).useNewUsersPage;
export const getUseNewChannelsPage = (state: RootState): boolean => getLaunchDarklyState(state).useNewChannelsPage;
export const getUseNewBrandsPage = (state: RootState): boolean => getLaunchDarklyState(state).useNewBrandsPage;
export const getUseBadgeReader = (state: RootState): boolean => getLaunchDarklyState(state).useBadgeReader;
export const getUseNewAnalyticsCommercialRealtimeBoard = (state: RootState): boolean =>
    getLaunchDarklyState(state).useNewAnalyticsCommercialRealtimeBoard;

export const { updateLDFlags } = launchDarklySlice.actions;
export default launchDarklySlice.reducer;
