import React from 'react';
import { MenuChannel, MenuPosDevice } from '@/services/innovorder/menu/menu.type';
import { CopyMenuModal } from './CopyMenuModal/CopyMenuModal';
import { CreateMenuModal } from './CreateMenuModal/CreateMenuModal';
import { DeleteMenuModal } from './DeleteMenuModal/DeleteMenuModal';
import { DuplicateMenuModal } from './DuplicateMenuModal/DuplicateMenuModal';
import { PublishMenuModal } from './PublishMenuModal/PublishMenuModal';
import { MenuTableActionTypes } from '../../types';
import { AIMenuScanModal } from '../AIMenuScanModal/AIMenuScanModal';

type MenuModalProps = {
    action: MenuTableActionTypes | null;
    onHide: () => void;
    brandId: number;
    restaurantId?: number;
    channels: MenuChannel[];
    posDevices: MenuPosDevice[];
    menuId: number | null;
    isSyncFromPosEnabled: boolean;
    menuName: string | null;
};

const getActionModal = (action: MenuTableActionTypes | null) => {
    switch (action) {
        case 'showCreateMenuModal':
        case 'showEditChannelModal':
            return CreateMenuModal;
        case 'showDuplicateModal':
            return DuplicateMenuModal;
        case 'showDeleteModal':
            return DeleteMenuModal;
        case 'showCopyModal':
            return CopyMenuModal;
        case 'showPublishModal':
            return PublishMenuModal;
        case 'showAIMenuScanModal':
            return AIMenuScanModal;
        default:
            return null;
    }
};

export const MenuModal: React.FunctionComponent<React.PropsWithChildren<MenuModalProps>> = ({
    action,
    onHide,
    brandId,
    restaurantId,
    channels,
    posDevices,
    isSyncFromPosEnabled,
    menuName,
    menuId,
}) => {
    const ActionModal = getActionModal(action);

    if (!ActionModal) {
        return ActionModal;
    }

    return (
        <ActionModal
            onHide={onHide}
            brandId={brandId}
            restaurantId={restaurantId}
            channels={channels}
            posDevices={posDevices}
            isSyncFromPosEnabled={isSyncFromPosEnabled}
            menuId={menuId}
            menuName={menuName}
        />
    );
};
