import styled from '@emotion/styled';

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: ${({ theme }) => theme.spacing[10]};
`;

export const SwitchRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing[3]};
    div {
        height: 22px;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${({ theme }) => theme.font.family};
    background-color: ${({ theme }) => theme.color.white200};
    margin: ${({ theme }) => theme.spacing[4]};
`;

export const Subtitle = styled.div`
    max-width: 500px;
    color: ${({ theme }) => theme.color.black200};
    font-size: ${({ theme }) => theme.font.size.small};
    margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

export const Left = styled.div`
    flex: 1;
    margin-right: ${({ theme }) => theme.spacing[4]};
    h2 {
        margin-bottom: ${({ theme }) => theme.spacing[4]};
    }
`;

export const Right = styled.div`
    flex: 3;
    border: 1px solid ${({ theme }) => theme.color.grey200};
    border-radius: ${({ theme }) => theme.radius.main};
    padding: ${({ theme }) => theme.spacing[4]};
`;

export const ActionBar = styled.div`
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: ${({ theme }) => theme.color.white200};
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[1]};
    padding: ${({ theme }) => theme.spacing[4]};
`;

export const ActionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[3]};
`;
