import React, { useEffect, useState } from 'react';
import { Table } from '@/components/atoms/Table';
import { Section } from '@/components/atoms/Section';
import { generatePath, useHistory, useParams } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Routes } from '@/core/router/routes.types';
import RouterNavigationTabs from '@/components/organisms/RouterNavigationTabs';
import {
    useDeleteGuestGroupMutation,
    useGetGuestGroupsQuery,
    useGetRelatedGuestsCountMutation,
} from '@/services/innovorder/guestGroup/guestGroup.endpoints';
import { Button } from '@/components/atoms/Button';
import { getUserToken } from '@/redux/user';
import { routes } from '@/core/router/routes';
import { Container } from './Groups.Style';
import { canGroupBeDeleted, getGroupsTableColumnsVM, getGroupsTableRowsVM } from './utils';
import DeleteGroupContainer from './DeleteGroupContainer/DeleteGroupContainer';

interface GroupsProps {
    brandId: string;
}

export const Groups: React.FunctionComponent<React.PropsWithChildren> = () => {
    const intl = useIntl();
    const history = useHistory();
    const { brandId } = useParams<GroupsProps>();
    const token = useSelector(getUserToken);

    const [groupIdToDelete, setGroupIdToDelete] = useState<number | null>(null);

    const { data: guestGroups, isLoading } = useGetGuestGroupsQuery(
        {
            brandId: parseInt(brandId, 10),
            token,
        },
        { refetchOnMountOrArgChange: true },
    );

    const [deleteGuestGroup, { isSuccess, isLoading: isLoadingDelete }] = useDeleteGuestGroupMutation();
    const [getRelatedGuestsCount, { data: relatedGuestsData, isLoading: isLoadingCount }] =
        useGetRelatedGuestsCountMutation();

    const handleOpenModal = (id: number) => {
        getRelatedGuestsCount({ token, groupId: id });
        setGroupIdToDelete(id);
    };
    const handleDeleteGroup = () => groupIdToDelete && deleteGuestGroup({ guestGroupId: groupIdToDelete, token });
    const handleCloseModal = () => setGroupIdToDelete(null);
    const handleUpdateClick = (groupId: number) => history.push(generatePath(Routes.EditGroup, { brandId, groupId }));
    const groupsTableRows = getGroupsTableRowsVM(intl, handleOpenModal, handleUpdateClick, guestGroups);
    const groupsTableColumns = getGroupsTableColumnsVM(intl);

    useEffect(() => {
        isSuccess && setGroupIdToDelete(null);
    }, [isSuccess]);
    const handleAddGroup = (): void => {
        history.push(generatePath(Routes.CreateGroup, { brandId }));
    };

    const tabRoutes = React.useMemo(() => routes.filter(({ tab }) => tab === 'brands'), []);

    return (
        <RouterNavigationTabs routes={tabRoutes}>
            <Container>
                <Section
                    title={'group.tableCard.title'}
                    subtitle={'group.tableCard.subtitle'}
                    actions={
                        <Button buttonType="primary" onClick={handleAddGroup}>
                            <FormattedMessage id="group.tableCard.action" />
                        </Button>
                    }
                >
                    <Table
                        rows={groupsTableRows}
                        columns={groupsTableColumns}
                        emptyMessageId="group.emptyMessage"
                        isSortable={false}
                        hasPagination={true}
                        isLoading={isLoading}
                    />
                </Section>
                <DeleteGroupContainer
                    canBeDeleted={canGroupBeDeleted(relatedGuestsData)}
                    isOpen={groupIdToDelete !== null}
                    isDeleteGroupLoading={isLoadingDelete}
                    onHide={handleCloseModal}
                    onSubmit={handleDeleteGroup}
                    isGuestsCountLoading={isLoadingCount}
                />
            </Container>
        </RouterNavigationTabs>
    );
};
