import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { useFormContext } from 'react-hook-form';
import { computeText } from '@/locales/utils';
import { ActiveStudentEntities } from '@/services/innovorder/brand/brand.type';
import { GuestGroupWithGuestsCount } from '@/services/innovorder/guestGroup/guestGroup.type';
import {
    PRICING_CONFIGURATION_LINKS,
    getAccountPaymentTypeOptions,
    getGuestGroupOptions,
    getRedirectionLink,
} from '@/pages/Customer/PricingConfigurationSection/PricingConfigurationSection.utils';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import {
    useGetSectionFieldSuggestions,
    useGetSubSectionFieldSuggestions,
} from '@/pages/Customer/PricingConfigurationSection/hooks';
import { getUserBrandId } from '@/redux/user';
import { Select } from '@/components/form/Select';
import { Input } from '@/components/form/Input';
import { FlexContainer, StyledInfoParagraph } from './UpdateInfoForm.style';

type UpdateCustomerFormProps = {
    guestGroups: GuestGroupWithGuestsCount[];
    activeStudentEntities?: ActiveStudentEntities;
};

const UpdateInfoForm: React.FunctionComponent<React.PropsWithChildren<UpdateCustomerFormProps>> = ({
    guestGroups,
    activeStudentEntities,
}) => {
    const intl = useIntl();
    const history = useHistory();
    const brandId = useSelector(getUserBrandId);
    const { getValues, reset } = useFormContext();
    const [selectedGuestGroup, setSelectedGuestGroup] = useState<GuestGroupWithGuestsCount | undefined>(undefined);

    const sectionsFieldSuggestions = useGetSectionFieldSuggestions(activeStudentEntities, selectedGuestGroup);
    const sectionValue = getValues('section');
    const subSectionFieldSuggestions = useGetSubSectionFieldSuggestions(
        sectionValue,
        activeStudentEntities,
        selectedGuestGroup,
    );

    const handleGuestGroupSelect = (value: number) => {
        const guestGroup = guestGroups.find(({ guestGroupId }) => guestGroupId === value);
        if (!guestGroup) {
            return;
        }
        setSelectedGuestGroup(guestGroup);
        const selectedGroupHasOnlyOnePricingRule = guestGroup?.pricingRules.length === 1;
        let pricingRuleCode;
        if (selectedGroupHasOnlyOnePricingRule) {
            pricingRuleCode = guestGroup.pricingRules[0].code;
        }
        reset({ ...getValues(), pricingRuleCode });
    };

    const handleRedirectionLinkClick = (link: string) => {
        if (brandId) {
            history.push(getRedirectionLink(link, brandId));
        }
    };

    return (
        <FlexContainer>
            <Select
                labelId="customer.student.info.account.type"
                name={'accountPaymentType'}
                rules={{
                    required: computeText(intl, 'field.required'),
                }}
                options={getAccountPaymentTypeOptions(intl)}
            />
            <div>
                <Select
                    labelId="customer.student.info.group"
                    name={'guestGroupId'}
                    rules={{
                        required: computeText(intl, 'field.required'),
                    }}
                    options={getGuestGroupOptions(guestGroups)}
                    // @ts-ignore
                    onChange={handleGuestGroupSelect}
                />
                <StyledInfoParagraph>
                    {computeText(intl, 'customer.form.guestGroupInfo')}
                    <span onClick={() => handleRedirectionLinkClick(PRICING_CONFIGURATION_LINKS.GUEST_GROUPS)}>
                        {computeText(intl, 'customer.form.here')}
                    </span>
                </StyledInfoParagraph>
            </div>
            <Input labelId="customer.student.info.section" name="section" suggestions={sectionsFieldSuggestions} />
            <Input
                labelId="customer.student.info.sub.section"
                name="subSection"
                suggestions={subSectionFieldSuggestions}
            />
            <Input labelId="customer.student.info.pricing.code" name={'tariffCode'} />
            <Input labelId="customer.student.info.student.number" name={'studentNumber'} />
            <Input labelId="customer.student.info.badge.number" name={'badgeNumber'} />
        </FlexContainer>
    );
};

export default UpdateInfoForm;
