import { SVGIcons } from '@/components/atoms/Icon/SVGIcon.interface';
import { getUserRole } from '@/redux/user';
import {
    useActivateRestaurantKdsMutation,
    useDeactivateRestaurantKdsMutation,
} from '@/services/innovorder/channel/channel.endpoints';
import { UserRoles } from '@/services/innovorder/user/user.type';
import { redirectToBackofficeV1 } from '@/utils/backoffice-v1';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

export type ChannelDisplayData = {
    nom: string;
    description: string;
    icon: SVGIcons;
    shouldDisplay: boolean;
    shouldAllowConfig: boolean;
    redirectToConfigPage: (event: React.MouseEvent<HTMLElement>) => void;
    overrideActivateAction?: () => void;
    overrideDeactivateAction?: () => void;
};

const useChannels = () => {
    const { params } = useRouteMatch<{ brandId: string; restaurantId: string }>();

    const brandId = Number.parseInt(params.brandId, 10);
    const restaurantId = params.restaurantId?.length ? Number.parseInt(params.restaurantId, 10) : undefined;
    const currentUserRole = useSelector(getUserRole);

    const [activateKds] = useActivateRestaurantKdsMutation();
    const [deactivateKds] = useDeactivateRestaurantKdsMutation();

    const ChannelsInformation: Record<number, ChannelDisplayData> = useMemo(
        () => ({
            1: {
                nom: 'channels.kiosk.name',
                description: 'channels.kiosk.description',
                icon: SVGIcons.COLORED_KIOSK,
                shouldDisplay: true,
                shouldAllowConfig: true,
                redirectToConfigPage: (ev) => {
                    const path = `/home${currentUserRole === UserRoles.restaurant ? '' : `/brands/${brandId}`}${
                        restaurantId ? `/restaurants/${restaurantId}` : ''
                    }/channels/kiosk/parameters`;
                    redirectToBackofficeV1(path, ev.ctrlKey);
                },
            },

            2: {
                nom: 'channels.web.name',
                description: 'channels.web.description',
                icon: SVGIcons.COLORED_WEB,
                shouldDisplay: true,
                shouldAllowConfig: true,
                redirectToConfigPage: (ev) => {
                    const path = `/home${currentUserRole === UserRoles.restaurant ? '' : `/brands/${brandId}`}${
                        restaurantId ? `/restaurants/${restaurantId}` : ''
                    }/channels/web/parameters`;
                    redirectToBackofficeV1(path, ev.ctrlKey);
                },
            },

            4: {
                nom: 'channels.reception.name',
                description: 'channels.reception.description',
                icon: SVGIcons.RECEPTION,
                shouldDisplay: true,
                shouldAllowConfig: true,
                redirectToConfigPage: (ev) => {
                    const path = `/home${currentUserRole === UserRoles.restaurant ? '' : `/brands/${brandId}`}${
                        restaurantId ? `/restaurants/${restaurantId}` : ''
                    }/channels/reception/refund`;
                    redirectToBackofficeV1(path, ev.ctrlKey);
                },
            },

            5: {
                nom: 'channels.pos.name',
                description: 'channels.pos.description',
                icon: SVGIcons.COLORED_POS,
                shouldDisplay: true,
                shouldAllowConfig: currentUserRole === UserRoles.admin && !!restaurantId,
                redirectToConfigPage: (ev) => {
                    const path = `/home${currentUserRole === UserRoles.restaurant ? '' : `/brands/${brandId}`}${
                        restaurantId ? `/restaurants/${restaurantId}` : ''
                    }/channels/pos/configuration`;
                    redirectToBackofficeV1(path, ev.ctrlKey);
                },
            },

            99: {
                nom: 'channels.kds.name',
                description: 'channels.kds.description',
                icon: SVGIcons.KITCHEN_DISPLAY,
                shouldDisplay: !(!restaurantId && currentUserRole === UserRoles.admin),
                shouldAllowConfig: !!restaurantId && currentUserRole === UserRoles.admin,
                redirectToConfigPage: (ev) => {
                    const path = `/home${currentUserRole === UserRoles.restaurant ? '' : `/brands/${brandId}`}${
                        restaurantId ? `/restaurants/${restaurantId}` : ''
                    }/channels/kitchen_display/settings`;
                    redirectToBackofficeV1(path, ev.ctrlKey);
                },
                overrideActivateAction: () => {
                    activateKds({ restaurantId: String(restaurantId) });
                },
                overrideDeactivateAction: () => {
                    deactivateKds({ restaurantId: String(restaurantId) });
                },
            },

            6: {
                nom: 'channels.loyalty.name',
                description: 'channels.loyalty.description',
                icon: SVGIcons.COLORED_FIDELITE,
                shouldDisplay: !(!!restaurantId && currentUserRole === UserRoles.admin),
                shouldAllowConfig: !restaurantId && currentUserRole === UserRoles.admin,
                redirectToConfigPage: (ev) => {
                    const path = `/home${currentUserRole === UserRoles.restaurant ? '' : `/brands/${brandId}`}${
                        restaurantId ? `/restaurants/${restaurantId}` : ''
                    }/channels/loyalty/royalty`;
                    redirectToBackofficeV1(path, ev.ctrlKey);
                },
            },

            7: {
                nom: 'channels.ewallet_kiosk.name',
                description: 'channels.ewallet_kiosk.description',
                icon: SVGIcons.EWALLET,
                shouldDisplay: !(!!restaurantId && currentUserRole === UserRoles.admin),
                shouldAllowConfig: !restaurantId && currentUserRole === UserRoles.admin,
                redirectToConfigPage: (ev) => {
                    const path = `/home${currentUserRole === UserRoles.restaurant ? '' : `/brands/${brandId}`}${
                        restaurantId ? `/restaurants/${restaurantId}` : ''
                    }/channels/ewallet_kiosk/configuration`;
                    redirectToBackofficeV1(path, ev.ctrlKey);
                },
            },

            8: {
                nom: 'channels.vending_machine.name',
                description: 'channels.vending_machine.description',
                icon: SVGIcons.VENDING_MACHINE,
                shouldDisplay: currentUserRole === UserRoles.admin,
                shouldAllowConfig: false,
                redirectToConfigPage: () => {},
            },
        }),
        [restaurantId, currentUserRole],
    );
    return ChannelsInformation;
};
export default useChannels;
