import styled from '@emotion/styled';

export const Container = styled.div`
    width: 100%;
    padding: 20px;

    > div {
        margin-bottom: 20px;
    }
`;
