import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import { StepData } from '@/components/atoms/Wizard/Wizard';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { computeText } from '@/locales/utils';
import { DriverConfigFormValues, InformationFormValues, ReadingConditionsFormValues } from './FormModal.types';
import {
    BadgeReader,
    BadgeReaderDriver,
    DriverConfigBaudRate,
    DriverConfigDataBits,
    DriverConfigParity,
    DriverConfigStopBits,
} from '@/services/innovorder/badgeReader/badgeReader.type';
import { InformationsStep } from './InformationsStep/InformationsStep';
import { DriverConfigStep } from './DriverConfigStep/DriverConfigStep';
import { ReadingConditionsStep } from './ReadingConditionsStep/ReadingConditionsStep';
import {
    useCreateBadgeReaderMutation,
    useUpdateBadgeReaderMutation,
} from '@/services/innovorder/badgeReader/badgeReader.endpoints';
import { useSelector } from 'react-redux';
import { getUserBrandId, getUserToken } from '@/redux/user';
import { stringifyError } from '@/utils/errors';
import { confirm } from '@/core/asyncActions/confirm';

const informationDefaultValues: InformationFormValues = {
    name: '',
    deviceId: '',
    device_uuid: '',
    driver: BadgeReaderDriver.Keyboard,
};

const driverDefaultValues: DriverConfigFormValues = {
    baudRate: DriverConfigBaudRate.BR9600,
    dataBits: DriverConfigDataBits.DB7,
    stopBits: DriverConfigStopBits.SB1,
    parity: DriverConfigParity.None,
};

const readingConditionsDefaultValues: { readingConditions: ReadingConditionsFormValues } = {
    readingConditions: [],
};

type FormModalVMProps = {
    data?: BadgeReader;
    onHide: () => void;
};

export const useFormModalVM = ({ data: defaultData, onHide }: FormModalVMProps) => {
    const intl = useIntl();
    const [currentStep, setCurrentStep] = useState(0);

    const token = useSelector(getUserToken);
    const brandId = useSelector(getUserBrandId) as number;
    const [createBadgeReader, { isLoading: isLoadingCreate, error: createError }] = useCreateBadgeReaderMutation();
    const [updateBadgeReader, { isLoading: isLoadingUpdate, error: updateError }] = useUpdateBadgeReaderMutation();

    const informationForm: UseFormReturn<InformationFormValues> = useForm<InformationFormValues>({
        mode: 'all',
        defaultValues: defaultData || informationDefaultValues,
    });
    const driverConfigForm: UseFormReturn<DriverConfigFormValues> = useForm<DriverConfigFormValues>({
        mode: 'all',
        defaultValues: defaultData ? defaultData.driverConfig : driverDefaultValues,
    });
    const readingConditionsForm: UseFormReturn<{ readingConditions: ReadingConditionsFormValues }> = useForm<{
        readingConditions: ReadingConditionsFormValues;
    }>({
        mode: 'all',
        defaultValues: defaultData || readingConditionsDefaultValues,
    });

    const driverValue = informationForm.getValues('driver');
    useEffect(() => {
        informationForm.watch('driver');
    }, [informationForm]);

    const onCancel = useCallback(async () => {
        const hasConfirmed = await confirm(
            computeText(intl, 'badgeReader.cancel.confirmation'),
            'badgeReader.cancel.warning',
        );
        if (hasConfirmed) {
            onHide();
            setCurrentStep(0);
            informationForm.reset();
        }
    }, [onHide]);

    const onPrevious = useCallback(() => {
        setCurrentStep((prev) => prev - 1);
        return true;
    }, [currentStep]);

    const onNext = (form: UseFormReturn<any>) => (): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            form.handleSubmit(
                () => resolve(true),
                () => resolve(false),
            )();
        });
    };

    const steps: StepData[] = useMemo(
        () => [
            {
                title: computeText(intl, 'badgeReader.step.information'),
                component: (
                    <FormProvider {...informationForm}>
                        <InformationsStep />
                    </FormProvider>
                ),
                onNext: onNext(informationForm),
                onCancel,
            },
            {
                title: computeText(intl, 'badgeReader.step.driverConfigs'),
                component: (
                    <FormProvider {...driverConfigForm}>
                        <DriverConfigStep />
                    </FormProvider>
                ),
                disabled: driverValue === BadgeReaderDriver.Keyboard,
                onNext: onNext(driverConfigForm),
                onCancel,
                onPrevious,
            },
            {
                title: computeText(intl, 'badgeReader.step.readingCondition'),
                component: (
                    <FormProvider {...readingConditionsForm}>
                        <ReadingConditionsStep />
                    </FormProvider>
                ),
                onCancel,
                onPrevious,
            },
        ],
        [driverValue],
    );

    const onSubmit = async () => {
        const isValid = await onNext(readingConditionsForm)();
        if (isValid) {
            const informations = informationForm.getValues();
            const driverConfigValues = driverConfigForm.getValues();
            const { readingConditions } = readingConditionsForm.getValues();
            const body = {
                name: informations.name,
                deviceId: informations.deviceId,
                device_uuid: informations.device_uuid,
                driver: informations.driver,
                token,
                driverConfig: driverValue === BadgeReaderDriver.Keyboard ? {} : driverConfigValues,
                readingConditions,
            };
            if (defaultData?.badgeReaderId) {
                updateBadgeReader({ ...body, badgeReaderId: defaultData.badgeReaderId })
                    .unwrap()
                    .then(onHide);
            } else {
                createBadgeReader({ ...body, brandId })
                    .unwrap()
                    .then(onHide);
            }
        }
    };

    return {
        steps,
        isLoading: isLoadingCreate || isLoadingUpdate,
        errorMessage: stringifyError(createError || updateError),
        onSubmit,
    };
};
