import { useTheme } from '@emotion/react';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import RestaurantSelector from '@/components/molecules/RestaurantSelector';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, fetchDataProps, Table } from '@/components/atoms/Table';
import { Title } from '@/components/atoms/Title';
import { Routes } from '@/core/router/routes.types';
import { getParamFromQueryString, replaceParamInUrl } from '@/utils/history';
import { useGetOrdersMutation } from '@/services/innovorder/orders/order.endpoints';
import { getBrandType } from '@/redux/brand';
import { getAssertedUserBrandId, getUserRestaurants, getUserRole, getUserToken } from '@/redux/user';
import ExportModal from './ExportModal';
import { Container, Header, Subtitle, TitleContainer } from './OrderList.style';
import {
    convertTableFiltersToQueryFilters,
    convertTableSortToQuerySort,
    getDefaultHiddenColumns,
    getOrdersTableColumnsVM,
    getOrdersTableRowsVM,
    getRestaurantsCondition,
} from './utils';
import { useGetExportButtons } from './hooks/useGetExportButtons';

const defaultSortBy = [{ id: 'orderDate', desc: true }];

const OrderList: React.FunctionComponent<React.PropsWithChildren> = () => {
    const intl = useIntl();
    const theme = useTheme();
    const token = useSelector(getUserToken);
    const brandId = useSelector(getAssertedUserBrandId);
    const brandType = useSelector(getBrandType);
    const restaurants = useSelector(getUserRestaurants);
    const userRole = useSelector(getUserRole);
    const history = useHistory();
    const { exportButtons, isExportModalVisible, setExportModalVisibility } = useGetExportButtons();

    const [selectedRestaurantId, setSelectedRestaurantId] = useState(
        Number(getParamFromQueryString(history.location.search, 'restaurant')) || 0,
    );

    const [fetchOrders, { data, isLoading }] = useGetOrdersMutation();

    const fetchData = useCallback(
        ({ pageIndex, pageSize, globalFilter, sortBy, columnFilters }: fetchDataProps) => {
            if (token) {
                const sortOrder = convertTableSortToQuerySort(sortBy);
                const filters = convertTableFiltersToQueryFilters(columnFilters);
                fetchOrders({
                    brandId,
                    restaurantIds: getRestaurantsCondition(selectedRestaurantId, userRole, restaurants),
                    token,
                    search: globalFilter,
                    filters,
                    limit: pageSize ?? DEFAULT_PAGE_SIZE,
                    offset:
                        pageIndex === undefined || pageSize === undefined ? DEFAULT_PAGE_INDEX : pageIndex * pageSize,
                    order: sortOrder,
                });
            }
        },
        [token, fetchOrders, brandId, selectedRestaurantId, userRole, restaurants],
    );

    const columns = useMemo(() => getOrdersTableColumnsVM(intl, brandType), [intl, brandType]);
    const rows = useMemo(() => getOrdersTableRowsVM(data?.omnichannelOrders, intl, theme), [data, intl, theme]);

    const handleRestaurantChange = useCallback(
        (restaurantId: number) => {
            setSelectedRestaurantId(restaurantId);
            replaceParamInUrl(history, 'restaurant', String(restaurantId));
        },
        [history],
    );

    const toggleExport = useCallback(() => {
        setExportModalVisibility((open: boolean) => !open);
    }, [setExportModalVisibility]);

    const defaultHiddenColumns = useMemo(() => {
        if (!brandType) return [];

        return getDefaultHiddenColumns(brandType);
    }, [brandType]);

    return (
        <Container>
            <Header>
                <TitleContainer>
                    <Title text="orderList.title" />
                    <Subtitle text="orderList.subtitle" />
                </TitleContainer>
                {restaurants && (
                    <RestaurantSelector
                        buttonLabel={
                            selectedRestaurantId
                                ? restaurants.find((restaurant) => restaurant.restaurantId === selectedRestaurantId)
                                      ?.name || '???'
                                : intl.formatMessage({ id: 'orderList.filters.allRestaurants' })
                        }
                        restaurants={restaurants}
                        selected={selectedRestaurantId}
                        onChange={handleRestaurantChange}
                        headerLabel="orderList.filters.restaurantHeader"
                    />
                )}
            </Header>
            {brandType && (
                <Table
                    rows={rows}
                    emptyMessageId="table.noRowsAfterFilter"
                    columns={columns}
                    hasPagination
                    hasUrlNavigation
                    isFilterable
                    isSortable
                    defaultSortBy={defaultSortBy}
                    fetchData={fetchData}
                    fetchDataTotalCount={data?.count}
                    isLoading={isLoading}
                    onRowClickPath={Routes.OrderDetails}
                    toolbarButtons={exportButtons}
                    columnSelectorId="orderList"
                    defaultHiddenColumns={defaultHiddenColumns}
                />
            )}
            {isExportModalVisible && (
                <ExportModal width={680} onHide={toggleExport} brandId={brandId} restaurantId={selectedRestaurantId} />
            )}
        </Container>
    );
};

export default OrderList;
