import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { getUseShift } from '@/redux/launchDarkly';

import { Input } from '@/components/atoms/Form/Input';
import { Text } from '@/components/atoms/Text';
import { SavingBar } from '@/components/atoms/SavingBar';
import Select, { Option } from '@/components/atoms/Select';

import { computeText } from '@/locales/utils';
import { computeAndTriggerTouchedFields } from '@/utils/form/utils';
import {
    BaseGrantCreateAttributes,
    GrantPayload,
    GrantStrategy,
    GrantType,
} from '@/services/innovorder/grant/grant.type';
import { getGrant, updateGrant } from '@/redux/grantAndEntranceFee';
import { GrantRestaurants } from './GrantRestaurants';
import {
    Container,
    FlexContainer,
    TitleContainer,
    CustomTitle,
    FormContainer,
    MargedTitle,
    SpacedFlex,
    GrantRestaurantsContainer,
    WarningFormContainer,
} from './GrantForm.style';
import GrantShifts from './GrantShifts';

export const GrantStrategyTranslationMap = {
    [GrantStrategy.MinimumAmountAndDailyReached]: 'grant.select.minimum',
    [GrantStrategy.MinimumAmountAndDailyReachedAndProduct]: 'grant.select.minimumAndProduct',
};

export const GrantStrategyMap: Option<GrantStrategy>[] = [
    {
        label: GrantStrategyTranslationMap[GrantStrategy.MinimumAmountAndDailyReached],
        value: GrantStrategy.MinimumAmountAndDailyReached,
    },
    {
        label: GrantStrategyTranslationMap[GrantStrategy.MinimumAmountAndDailyReachedAndProduct],
        value: GrantStrategy.MinimumAmountAndDailyReachedAndProduct,
    },
];

// TODO: adapt to backendName
export const grantTypeMap: Option<GrantType>[] = [
    { label: 'grant.select.absolute', value: 'ABSOLUTE' },
    { label: 'grant.select.rate', value: 'RATE' },
];

type GrantFormProps = {
    grant?: GrantPayload;
    error?: string;
    onSubmit: () => void;
    loading: boolean;
    isCreateMode?: boolean;
};

const GrantForm: React.FunctionComponent<React.PropsWithChildren<GrantFormProps>> = ({
    grant,
    error: callError,
    loading,
    isCreateMode,
    onSubmit,
}) => {
    const intl = useIntl();
    const [modal, setModal] = useState<{ restaurantId: number; name: string } | null>(null);

    const dispatch = useDispatch();
    const useShift = useSelector(getUseShift);
    const grantFromState = useSelector(getGrant);

    const {
        control,
        formState: { touchedFields, dirtyFields, isSubmitSuccessful, isValid },
        handleSubmit,
        watch,
        setValue,
        getValues,
        trigger,
        reset,
    } = useForm<BaseGrantCreateAttributes>({
        defaultValues: {
            strategy: GrantStrategy.MinimumAmountAndDailyReached,
            type: 'ABSOLUTE',
            restaurants: [],
            ...grant,
        },
    });

    const handleOnConfigure = ({ restaurantId, name }: { restaurantId: number; name: string }) => {
        isValid && setModal({ restaurantId, name });
    };

    const handleOnHide = () => {
        setModal(null);
    };

    const finalTouchedFields = computeAndTriggerTouchedFields(grant, touchedFields, trigger);

    const isPercentage = watch('type') === 'RATE';

    const handleCancelChanges = (): void => {
        reset(
            isCreateMode
                ? {
                      strategy: GrantStrategy.MinimumAmountAndDailyReached,
                      type: 'ABSOLUTE',
                      amount: 0,
                      ceiling: null,
                      code: '',
                      dailyLimit: 0,
                      labelTicket: '',
                      tag: null,
                      threshold: null,
                  }
                : {
                      strategy: GrantStrategy.MinimumAmountAndDailyReached,
                      type: 'ABSOLUTE',
                      ...grant,
                  },
        );
    };

    useEffect(() => {
        trigger('ceiling');
    }, [isPercentage, trigger]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset(getValues());
        }
    }, [isSubmitSuccessful, getValues, reset]);

    useEffect(() => {
        const subscription = watch((value) => {
            const updatedValue = useShift ? { ...value, restaurants: grantFromState.restaurants } : { ...value };
            dispatch(updateGrant(updatedValue));
        });

        return () => subscription.unsubscribe();
    }, [watch, dispatch, useShift, grantFromState]);

    return (
        <>
            <Container>
                <form>
                    <FlexContainer>
                        <div style={{ flex: 1, paddingRight: 50 }}>
                            <TitleContainer>
                                <CustomTitle text={grant ? 'grant.title' : 'grant.create'} />
                                {grant && <CustomTitle text={grant.code} />}
                            </TitleContainer>
                            <Text text="grant.description" />
                            <br />
                            <Text text="grant.description.title1" weight="bold" />
                            <Text text="grant.desc1" />
                            <br />
                            <Text text="grant.description.title2" weight="bold" />
                            <Text text="grant.desc2" />
                        </div>
                        <FormContainer data-testid="grant-form">
                            <MargedTitle text="grant.default" weight="medium" />
                            <SpacedFlex>
                                <Controller
                                    control={control}
                                    name="code"
                                    rules={{ required: computeText(intl, 'field.required') }}
                                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                        <Input
                                            error={error?.message}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            placeholder="grant.code"
                                            labelId="grant.code"
                                            isTouched={finalTouchedFields.code}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="labelTicket"
                                    rules={{ required: computeText(intl, 'field.required') }}
                                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                        <Input
                                            error={error?.message}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            placeholder="grant.labelTicket"
                                            labelId="grant.labelTicket"
                                            isTouched={finalTouchedFields.labelTicket}
                                        />
                                    )}
                                />
                            </SpacedFlex>
                            <Controller
                                control={control}
                                name="threshold"
                                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                    <Input
                                        error={error?.message}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value?.toString() ?? ''}
                                        placeholder="grant.threshold"
                                        type="number"
                                        unitType="currency"
                                        labelId="grant.threshold"
                                        isTouched={value ? finalTouchedFields.threshold : undefined}
                                        min={0}
                                        max={999999999}
                                    />
                                )}
                            />
                            <SpacedFlex>
                                <Controller
                                    control={control}
                                    name="amount"
                                    rules={{ required: computeText(intl, 'field.required') }}
                                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                                        return (
                                            <Input
                                                error={
                                                    error?.message ||
                                                    (error?.type && computeText(intl, 'field.greaterThanZero'))
                                                }
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value?.toString() ?? ''}
                                                placeholder="grant.amount"
                                                type="number"
                                                unitType={isPercentage ? 'percentage' : 'currency'}
                                                labelId="grant.amount"
                                                isTouched={finalTouchedFields.amount}
                                                min={0}
                                                max={999999999}
                                            />
                                        );
                                    }}
                                />
                                <Controller
                                    control={control}
                                    name="type"
                                    rules={{ required: computeText(intl, 'field.required') }}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <Select<GrantType>
                                            value={value}
                                            options={grantTypeMap}
                                            onChange={(type) => {
                                                onChange(type);
                                                if (type === 'ABSOLUTE') {
                                                    setValue('ceiling', null);
                                                }
                                            }}
                                            labelId="grant.type"
                                            onBlur={onBlur}
                                            isTouched={value ? finalTouchedFields.type : undefined}
                                        />
                                    )}
                                />
                            </SpacedFlex>
                            <SpacedFlex>
                                <Controller
                                    control={control}
                                    name="ceiling"
                                    rules={
                                        isPercentage
                                            ? { required: computeText(intl, 'field.required') }
                                            : { required: false }
                                    }
                                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                                        return (
                                            <Input
                                                error={error?.message}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value?.toString() ?? ''}
                                                placeholder="grant.ceiling"
                                                type="number"
                                                unitType="currency"
                                                labelId="grant.ceiling"
                                                disabled={!isPercentage}
                                                isTouched={isPercentage && finalTouchedFields.ceiling}
                                                min={0}
                                                max={999999999}
                                            />
                                        );
                                    }}
                                />
                                <Controller
                                    control={control}
                                    name="dailyLimit"
                                    rules={{ required: computeText(intl, 'field.required') }}
                                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                                        return (
                                            <Input
                                                error={
                                                    error?.message ||
                                                    (error?.type && computeText(intl, 'field.greaterThanZero'))
                                                }
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value?.toString()}
                                                placeholder="grant.dailyLimit"
                                                type="number"
                                                labelId="grant.dailyLimit"
                                                isTouched={finalTouchedFields.dailyLimit}
                                                min={0}
                                                max={999999999}
                                            />
                                        );
                                    }}
                                />
                            </SpacedFlex>
                            <Controller
                                control={control}
                                name="tag"
                                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                    <Input
                                        error={error?.message}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value?.toString()}
                                        placeholder="grant.tags.placeholder"
                                        labelId="grant.tags"
                                        isTouched={value ? finalTouchedFields.tag : undefined}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="strategy"
                                rules={{ required: computeText(intl, 'field.required') }}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <Select<GrantStrategy>
                                        value={value}
                                        options={GrantStrategyMap}
                                        onChange={onChange}
                                        labelId="grant.strategy"
                                        onBlur={onBlur}
                                        isTouched={finalTouchedFields.strategy}
                                    />
                                )}
                            />
                        </FormContainer>
                    </FlexContainer>

                    {modal && (
                        <GrantShifts
                            restaurantId={modal.restaurantId}
                            name={modal.name}
                            grantType={getValues().type}
                            handleOnHide={handleOnHide}
                            isCreateMode={!!isCreateMode}
                        />
                    )}
                </form>

                <CustomTitle text="grant.customUse" />
                <Text text="grant.descCustomUse" />

                {!useShift && <Text text="grant.warningLimit" />}
                {useShift && !isValid && (
                    <WarningFormContainer>
                        <Text text="grant.warningForm" />
                    </WarningFormContainer>
                )}

                <div style={{ marginTop: 20 }}>
                    <GrantRestaurantsContainer useShift={useShift} isValid={isValid}>
                        <GrantRestaurants
                            unitType={isPercentage ? 'percentage' : 'currency'}
                            isCreateMode={isCreateMode}
                            handleOnConfigure={handleOnConfigure}
                        />
                    </GrantRestaurantsContainer>
                </div>
            </Container>
            <SavingBar
                onSave={handleSubmit(onSubmit)}
                error={callError}
                loading={loading}
                changesCount={Object.keys(dirtyFields).length}
                onCancel={handleCancelChanges}
            />
        </>
    );
};

export default GrantForm;
