import { UserRestaurant } from '@/services/innovorder/user/user.type';
import { SelectOptionInterface } from '@/components/atoms/MultiSelect';
import { MultiValue } from 'react-select';
import { getDateComponents } from '@/utils/date';
import { ReportFilterValues } from '@/pages/Reporting/common/types';
import { PricingRule } from '@/services/innovorder/pricingRule/pricingRule.type';
import { ReportType } from '@/pages/Reporting/SalesSummary/hooks';

const COLLECTIVE_CATERING_SALES_SUMMARY_EXPORT_URL = '/reports/v2/collective_catering_sales_summary_report';
const SALES_SUMMARY_EXPORT_URL = '/reports/v2/sales_summary_report';
export const getExportUrl = (reportType: ReportType): string => {
    switch (reportType) {
        case ReportType.COLLECTIVE_CATERING_SALES_SUMMARY:
            return COLLECTIVE_CATERING_SALES_SUMMARY_EXPORT_URL;
        case ReportType.SALES_SUMMARY:
            return SALES_SUMMARY_EXPORT_URL;
        default:
            throw new Error('Sales summary export type not supported!');
    }
};

export const mapRestaurantToMultiOption = (restaurants?: UserRestaurant[]): SelectOptionInterface<number>[] => {
    let options: SelectOptionInterface<number>[] = [];
    if (restaurants) {
        options = restaurants.map(({ restaurantId, name }) => ({ value: restaurantId, label: name }));
    }
    return options;
};

export const mapGroupsToMultiOption = (groups?: string[]): SelectOptionInterface<string>[] => {
    let options: SelectOptionInterface<string>[] = [];
    if (groups) {
        options = groups.map((group) => ({ value: group, label: group }));
    }
    return options;
};

export const mapPricingRulesToMultiOption = (pricingRules?: PricingRule[]): SelectOptionInterface<string>[] => {
    let options: SelectOptionInterface<string>[] = [];
    if (pricingRules) {
        options = pricingRules.map((pricingRule) => ({ value: pricingRule.code, label: pricingRule.code }));
    }
    return options;
};

export type Period = { startDate: Date | null; endDate: Date | null };

export const shouldUseGroupsAndPricingRulesFilters = (selectedReportType: ReportType): boolean =>
    selectedReportType === ReportType.COLLECTIVE_CATERING_SALES_SUMMARY;

export const getExportParams = (
    brandId: number,
    period: Period,
    restaurants: MultiValue<SelectOptionInterface<number>>,
    groups: MultiValue<SelectOptionInterface<string>>,
    pricingRules: MultiValue<SelectOptionInterface<string>>,
    selectedReportType: ReportType,
): Record<string, string | number | ReportFilterValues> => {
    if (period.startDate && period.endDate) {
        const exportParams: Record<string, string | number | ReportFilterValues> = {
            brand_id: brandId,
            startDate: JSON.stringify(getDateComponents(period.startDate)),
            endDate: JSON.stringify(getDateComponents(period.endDate)),
            restaurantIds: restaurants.map(({ value }) => value),
        };
        if (shouldUseGroupsAndPricingRulesFilters(selectedReportType)) {
            exportParams.groups = groups.map(({ value }) => value);
            exportParams.pricingRules = pricingRules.map(({ value }) => value);
        }
        return exportParams;
    }
    return {};
};
