import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { getUserToken } from '@/redux/user';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Badge, ICustomerAttributes, UpdateCustomerType } from '@/services/innovorder/customer/customer.types';
import { GuestGroupWithGuestsCount } from '@/services/innovorder/guestGroup/guestGroup.type';
import { ActiveStudentEntities, PaymentConfiguration } from '@/services/innovorder/brand/brand.type';
import { Switch } from '@/components/form/Switch';
import { Button } from '@/components/atoms/Button';
import { Text } from '@/components/atoms/Text';
import { Title } from '@/components/atoms/Title';
import { PriceInput } from '@/components/form/PriceInput';
import {
    useAddBadgeMutation,
    useDeleteBadgeMutation,
    useUpdateBadgeMutation,
    useUpdateCustomerMutation,
} from '@/services/innovorder/customer/customer.endpoints';
import { useLazyGetGrantsQuery } from '@/services/innovorder/grant/grant.endpoints';
import { useLazyGetEntranceFeesQuery } from '@/services/innovorder/entranceFee/entranceFee.endpoints';
import { useLazyGetPricingRulesQuery } from '@/services/innovorder/pricingRule/pricingRule.endpoints';
import { getCustomerDefaultValues, getErrorMessage, prepareUpdateCustomerPayload } from './Student.utils';
import { ActionBar, ActionContainer, Container, Left, Right, Row, Subtitle, SwitchRow } from './Student.style';
import UpdateInfoForm from './UpdateInfoForm';
import PricingRuleForm from './PricingRuleForm';
import BadgeForm from './BadgeForm';
import { BadgeFormValues } from './BadgeForm/Modal';

export type StudentProps = {
    customer: ICustomerAttributes;
    guestGroups: GuestGroupWithGuestsCount[];
    activeStudentEntities?: ActiveStudentEntities;
    brandPaymentConfiguration?: PaymentConfiguration | null;
    refetch: () => void;
};

const Student: React.FunctionComponent<React.PropsWithChildren<StudentProps>> = ({
    customer,
    guestGroups,
    activeStudentEntities,
    brandPaymentConfiguration,
    refetch,
}) => {
    const token = useSelector(getUserToken);
    const [errorMessage, setErrorMessage] = useState('');

    const [getGrants, { data: grants }] = useLazyGetGrantsQuery();
    const [getPricingRules, { data: pricingRules }] = useLazyGetPricingRulesQuery();
    const [getEntranceFees, { data: entranceFees }] = useLazyGetEntranceFeesQuery();

    const [updateCustomer, { isLoading, error, reset }] = useUpdateCustomerMutation();
    const [deleteBadge, { isLoading: isLoadingDeleteBadge }] = useDeleteBadgeMutation();
    const [addBadge, { isLoading: isLoadingAddBadge, error: errorAddBadge, reset: resetAddBadge }] =
        useAddBadgeMutation();
    const [updateBadge, { isLoading: isLoadingEditBadge, error: errorUpdateBadge, reset: resetUpdateBadge }] =
        useUpdateBadgeMutation();

    const customerForm: UseFormReturn<UpdateCustomerType> = useForm<UpdateCustomerType>({
        defaultValues: { ...getCustomerDefaultValues(customer) },
        mode: 'all',
    });
    const watchOverrideEwalletOverdraft = customerForm.watch('overrideEwalletOverdraft');

    const onCancel = () => {
        customerForm.reset();
    };

    const onSave = async () => {
        await updateCustomer({
            ...prepareUpdateCustomerPayload(customer, customerForm.getValues()),
            token,
        });
        await refetch();
    };

    const onSaveBadge = async (badge: BadgeFormValues) => {
        const data = {
            badgeId: badge.badgeId,
            type: badge.type,
            value: badge.value,
            isDefault: badge.isDefault,
            customerId: customer.customerId,
            isBlocked: badge.isBlocked || false,
            token,
        };
        const response = data.badgeId ? await updateBadge(data) : await addBadge(data);
        if ('data' in response) {
            await refetch();
        }
        return response;
    };

    const onDeleteBadge = async (badge: Badge) => {
        const data = {
            badgeId: badge.badgeId,
            customerId: badge.customerId,
            token,
        };
        await deleteBadge(data);
        await refetch();
    };

    const onResetErrorMessage = () => {
        setErrorMessage('');
        reset();
        resetAddBadge();
        resetUpdateBadge();
    };

    useEffect(() => {
        if (error || errorAddBadge || errorUpdateBadge)
            setErrorMessage(
                getErrorMessage([
                    error as FetchBaseQueryError,
                    errorAddBadge as FetchBaseQueryError,
                    errorUpdateBadge as FetchBaseQueryError,
                ]),
            );
    }, [error, errorAddBadge, errorUpdateBadge]);

    useEffect(() => {
        customerForm.reset(getCustomerDefaultValues(customer));
    }, [customer]);

    useEffect(() => {
        if (customer?.brandId) {
            getEntranceFees({ brandId: customer?.brandId, token });
            getGrants({ brandId: customer?.brandId, token });
            getPricingRules({ brandId: customer?.brandId, token });
        }
    }, [customer?.brandId]);

    return (
        <FormProvider {...customerForm}>
            <Container>
                <Row>
                    <Left>
                        <Title text="customer.student.info.title" />
                        <Subtitle>
                            <Text text="customer.student.info.desctiption" />
                        </Subtitle>
                    </Left>
                    <Right>
                        <UpdateInfoForm guestGroups={guestGroups} activeStudentEntities={activeStudentEntities} />
                        <BadgeForm
                            onSave={onSaveBadge}
                            onDelete={onDeleteBadge}
                            onResetErrorMessage={onResetErrorMessage}
                            badges={customer.badges}
                            errorMessage={errorMessage}
                            isLoading={isLoadingAddBadge || isLoadingDeleteBadge || isLoadingEditBadge}
                        />
                    </Right>
                </Row>
                <Row>
                    <Left>
                        <SwitchRow>
                            <Title text="customer.student.pricing.rules.title" />
                        </SwitchRow>
                        <Subtitle>
                            <Text text="customer.student.pricing.rules.desctiption" />
                        </Subtitle>
                    </Left>
                    <Right>
                        <PricingRuleForm pricingRules={pricingRules} entranceFees={entranceFees} grants={grants} />
                    </Right>
                </Row>
                {brandPaymentConfiguration?.useEwalletOverdraft && (
                    <Row>
                        <Left>
                            <SwitchRow>
                                <Title text="customer.student.overdraft.title" />
                                <Switch name={'overrideEwalletOverdraft'} key={'overrideEwalletOverdraft'} />
                            </SwitchRow>
                            <Subtitle>
                                <Text text="customer.student.overdraft.desctiption" />
                            </Subtitle>
                        </Left>
                        <Right>
                            {watchOverrideEwalletOverdraft ? (
                                <PriceInput
                                    required
                                    labelId="customer.student.overdraft.title"
                                    name="ewalletOverdraftAmount"
                                />
                            ) : (
                                <Text text="customer.student.overdraft.disabled" />
                            )}
                        </Right>
                    </Row>
                )}
                <Row>
                    <Left>
                        <SwitchRow>
                            <Title text="customer.student.badge.title" />
                            <Switch name={'isBadgeBlocked'} key={'isBadgeBlocked'} />
                        </SwitchRow>
                        <Subtitle>
                            <Text text="customer.student.badge.description" />
                        </Subtitle>
                    </Left>
                </Row>

                <ActionBar>
                    <ActionContainer>
                        <Button buttonType="red" onClick={onCancel} disabled={false}>
                            <FormattedMessage id="button.cancel" />
                        </Button>

                        <Button buttonType="primary" onClick={onSave} isLoading={isLoading} disabled={isLoading}>
                            <FormattedMessage id="button.save" />
                        </Button>
                    </ActionContainer>
                </ActionBar>
            </Container>
        </FormProvider>
    );
};

export default Student;
