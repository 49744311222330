import { generateQueryStringFromParams } from '@/utils/history';
import { innovorderApi } from '../index';
import { ApiRequest } from '../request.types';
import {
    EditCustomerLoyaltiesRequest,
    EditCustomerLoyaltiesResponse,
    EditCustomerLoyaltiesResult,
    UnarchiveCustomerRequest,
    UnarchiveCustomerResponse,
    UnarchiveCustomerResult,
    AddUserToRestaurantRequest,
    AddUserToRestaurantResponse,
    AddUserToRestaurantResult,
    DeleteUnassignedImagesRequest,
    DeleteUnassignedImagesResponse,
    DeleteUnassignedImagesResult,
    DeleteTransactionRequest,
    DeleteTransactionResponse,
    DeleteTransactionResult,
    ImportGeojsonPolygonRequest,
    ImportGeojsonPolygonResponse,
    ImportGeojsonPolygonResult,
    CopySchedulesRequest,
    CopySchedulesResponse,
    CopySchedulesResult,
    DeleteCustomerRequest,
    DeleteCustomerResponse,
    DeleteCustomerResult,
    RazCustomerRequest,
    RazCustomerResponse,
    RazCustomerResult,
    EditProductSkuFromProductIdRequest,
    EditProductSkuFromProductIdResponse,
    EditProductSkuFromProductIdResult,
    EditProductTagFromProductIdRequest,
    EditProductTagFromProductIdResponse,
    EditProductTagFromProductIdResult,
    MakeEwalletTransactionRequest,
    MakeEwalletTransactionResponse,
    MakeEwalletTransactionResult,
    EditPasswordExpiresAtRequest,
    EditPasswordExpiresAtResponse,
    EditPasswordExpiresAtResult,
    TransformGuestFileRequest,
    TransformGuestFileResponse,
    TransformGuestFileResult,
    ImportProductInMenuRequest,
    ImportProductInMenuResponse,
    ImportProductInMenuResult,
    EditProductInfoFromProductIdRequest,
    EditProductInfoFromProductIdResponse,
    EditProductInfoFromProductIdResult,
} from './adminTools.type';

const adminToolsApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        editCustomerLoyalties: build.mutation<EditCustomerLoyaltiesResult, EditCustomerLoyaltiesRequest>({
            query: ({ brandId, emailsOrCards, loyaltyAmountToAdd }) => ({
                url: '/scripts/edit_customer_loyalties',
                method: 'POST',
                body: {
                    brandId,
                    emailsOrCards,
                    loyaltyAmountToAdd,
                },
            }),
            transformResponse: (response: EditCustomerLoyaltiesResponse) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        unarchiveCustomer: build.mutation<UnarchiveCustomerResult, ApiRequest<UnarchiveCustomerRequest>>({
            query: ({ customerId }) => ({
                url: '/scripts/unarchive_customer',
                method: 'POST',
                body: {
                    customerId,
                },
            }),
            transformResponse: (response: UnarchiveCustomerResponse) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        addUserToRestaurant: build.mutation<AddUserToRestaurantResult, AddUserToRestaurantRequest>({
            query: ({ email, restaurantId }) => ({
                url: '/scripts/add_user_to_restaurant',
                method: 'POST',
                body: {
                    email,
                    restaurantId,
                },
            }),
            transformResponse: (response: AddUserToRestaurantResponse) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        deleteUnassignedImages: build.mutation<DeleteUnassignedImagesResult, DeleteUnassignedImagesRequest>({
            query: ({ brandId }) => ({
                url: '/scripts/delete_unassigned_images',
                method: 'POST',
                body: {
                    brandId,
                },
            }),
            transformResponse: (response: DeleteUnassignedImagesResponse) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        deleteTransaction: build.mutation<DeleteTransactionResult, DeleteTransactionRequest>({
            query: ({ transactionId }) => ({
                url: '/scripts/delete_transaction',
                method: 'POST',
                body: {
                    transactionId,
                },
            }),
            transformResponse: (response: DeleteTransactionResponse) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        importGeojsonPolygon: build.mutation<ImportGeojsonPolygonResult, ImportGeojsonPolygonRequest>({
            query: ({ restaurantId, areaName, geoJsonPolygon }) => ({
                url: '/scripts/import_geojson_polygon',
                method: 'POST',
                body: {
                    restaurantId,
                    areaName,
                    geoJsonPolygon,
                },
            }),
            transformResponse: (response: ImportGeojsonPolygonResponse) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        deleteCustomer: build.mutation<DeleteCustomerResult, ApiRequest<DeleteCustomerRequest>>({
            query: ({ customerId }) => ({
                url: '/scripts/delete_customer',
                method: 'POST',
                body: {
                    customerId,
                },
            }),
            transformResponse: (response: DeleteCustomerResponse) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        razCustomer: build.mutation<RazCustomerResult, ApiRequest<RazCustomerRequest>>({
            query: ({ customerId, date }) => ({
                url: '/scripts/raz_customer',
                method: 'POST',
                body: {
                    customerId,
                    date,
                },
            }),
            transformResponse: (response: RazCustomerResponse) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        copySchedules: build.mutation<CopySchedulesResult, CopySchedulesRequest>({
            query: ({ scheduleType, idSource, idsDestination }) => ({
                url: '/scripts/copy_schedules',
                method: 'POST',
                body: {
                    scheduleType,
                    idSource,
                    idsDestination,
                },
            }),
            transformResponse: (response: CopySchedulesResponse) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        editProductSkuFromProductId: build.mutation<
            EditProductSkuFromProductIdResult,
            EditProductSkuFromProductIdRequest
        >({
            query: ({ file }) => {
                const formData = new FormData();
                formData.append('file', file);
                return {
                    url: '/scripts/edit_product_sku_from_product_id',
                    method: 'POST',
                    body: formData,
                };
            },
            transformResponse: (response: EditProductSkuFromProductIdResponse) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        importProductInMenu: build.mutation<ImportProductInMenuResult, ImportProductInMenuRequest>({
            query: ({ file }) => {
                const formData = new FormData();
                formData.append('file', file);
                return {
                    url: '/scripts/import_product_in_menu_id',
                    method: 'POST',
                    body: formData,
                };
            },
            transformResponse: (response: ImportProductInMenuResponse) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        EditProductInfoFromProductId: build.mutation<
            EditProductInfoFromProductIdResult,
            EditProductInfoFromProductIdRequest
        >({
            query: ({ file }) => {
                const formData = new FormData();
                formData.append('file', file);
                return {
                    url: '/scripts/edit_product_info_from_product_id',
                    method: 'POST',
                    body: formData,
                };
            },
            transformResponse: (response: EditProductInfoFromProductIdResponse) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        editProductTagFromProductId: build.mutation<
            EditProductTagFromProductIdResult,
            EditProductTagFromProductIdRequest
        >({
            query: ({ file }) => {
                const formData = new FormData();
                formData.append('file', file);
                return {
                    url: '/scripts/edit_product_tag_from_product_id',
                    method: 'POST',
                    body: formData,
                };
            },
            transformResponse: (response: EditProductTagFromProductIdResponse) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        makeEwalletTransaction: build.mutation<MakeEwalletTransactionResult, MakeEwalletTransactionRequest>({
            query: ({ file }) => {
                const formData = new FormData();
                formData.append('file', file);
                return {
                    url: '/scripts/make_ewallet_transaction',
                    method: 'POST',
                    body: formData,
                };
            },
            transformResponse: (response: MakeEwalletTransactionResponse) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        editPasswordExpiresAt: build.mutation<EditPasswordExpiresAtResult, EditPasswordExpiresAtRequest>({
            query: ({ shouldResetPassword, brandId, customerId }) => ({
                url: '/scripts/edit_passwordExpiresAt',
                method: 'POST',
                body: {
                    shouldResetPassword,
                    brandId,
                    customerId: customerId || '',
                },
            }),
            transformResponse: (response: EditPasswordExpiresAtResponse) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        transformGuestFile: build.mutation<TransformGuestFileResult, TransformGuestFileRequest>({
            query: ({ file, fileFormat }) => {
                const queryString = generateQueryStringFromParams({ fileFormat });
                const formData = new FormData();
                formData.append('file', file);
                return {
                    url: `/customers/guest-file-mapping?${queryString}`,
                    method: 'POST',
                    body: formData,
                };
            },
            transformResponse: (response: TransformGuestFileResponse) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const {
    useEditCustomerLoyaltiesMutation,
    useUnarchiveCustomerMutation,
    useAddUserToRestaurantMutation,
    useDeleteUnassignedImagesMutation,
    useDeleteTransactionMutation,
    useImportGeojsonPolygonMutation,
    useDeleteCustomerMutation,
    useRazCustomerMutation,
    useCopySchedulesMutation,
    useEditProductSkuFromProductIdMutation,
    useImportProductInMenuMutation,
    useEditProductInfoFromProductIdMutation,
    useEditProductTagFromProductIdMutation,
    useMakeEwalletTransactionMutation,
    useEditPasswordExpiresAtMutation,
    useTransformGuestFileMutation,
} = adminToolsApi;
