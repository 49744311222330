import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Cell, Column } from 'react-table';
import saveAs from 'file-saver';
import { theme } from '@/core/theme';
import { computeText } from '@/locales/utils';
import { ENV } from '@/utils/env';
import { Routes } from '@/core/router/routes.types';
import { getChannelOrDeviceText } from '@/utils/innovorder/channel';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { getChannelIcon } from '@/components/atoms/Icon/utils';
import type { TableCell, TableRow } from '@/components/atoms/Table/Table.type';
import type { Menu } from '@/services/innovorder/menu/menu.type';
import { useGetMenusQuery, useGetMenuExportByIdMutation } from '@/services/innovorder/menu/menu.endpoints';
import { ChannelId } from '@/services/innovorder/channel/channel.type';
import { getUseNewEditMenuPage } from '@/redux/launchDarkly';
import { getUser, getUserToken } from '@/redux/user';
import { MenuRoutes } from '@/components/templates/Menu/Menu.interface';
import { AccessFlags } from '@/services/innovorder/auth/auth.type';
import { compareIsActive } from './utils';
import { MenuTableAction, MenuTableActionTypes } from './types';

const defaultMenuAction: MenuTableAction = {
    menuId: null,
    name: null,
    channels: [],
    posDevices: [],
    isSyncFromPosEnabled: true,
    type: null,
};

export type MenuTableViewModel = {
    headers: Column<TableRow>[];
    rows: TableRow[];
    action: MenuTableAction;
    brandId: number;
    restaurantId?: number;
    isGetMenuLoading: boolean;
    isExportMenuLoading: boolean;
    handleRowClick: (
        cell: Cell<TableRow, TableCell<unknown>> | undefined,
        event?: React.MouseEvent<HTMLTableCellElement>,
    ) => void;
    clearAction: () => void;
    toggleCreateModal: () => void;
    toggleAIMenuScanModal: () => void;
    goToImportPage: () => void;
    userAccessFlags: Record<AccessFlags, boolean> | undefined;
};

export function useMenuTableVM(): MenuTableViewModel {
    const intl = useIntl();
    const history = useHistory();
    const { params, path } = useRouteMatch<{ brandId: string; restaurantId: string }>();
    const brandId = Number.parseInt(params.brandId, 10);
    const restaurantId = params.restaurantId?.length ? Number.parseInt(params.restaurantId, 10) : undefined;
    const token = useSelector(getUserToken);
    const useNewEditMenuPages = useSelector(getUseNewEditMenuPage);
    const user = useSelector(getUser);
    const userAccessFlags = user?.accessFlags;

    const menuQuery = restaurantId ? { restaurantId } : { brandId };
    const { data: menus, isLoading: isGetMenuLoading } = useGetMenusQuery({ ...menuQuery });
    const [getMenuExportById, { data: menuExport, isLoading: isExportMenuLoading }] = useGetMenuExportByIdMutation();
    const [action, setAction] = useState<MenuTableAction>(defaultMenuAction);
    const { name, menuId, type } = action;

    const toggleCreateModal = (): void => {
        setAction({ ...defaultMenuAction, type: 'showCreateMenuModal' });
    };

    const toggleAIMenuScanModal = (): void => {
        setAction({ ...defaultMenuAction, type: 'showAIMenuScanModal' });
    };

    const handleAction = React.useCallback(
        (
            actionType: MenuTableActionTypes,
            { menuId: actionMenuId, name: actionName, channels, isSyncFromPosEnabled, posDevices }: Menu,
        ) => {
            setAction({
                menuId: actionMenuId,
                name: actionName,
                channels,
                posDevices,
                isSyncFromPosEnabled,
                type: actionType,
            });
        },
        [],
    );

    const goToImportPage = (): void => {
        if (restaurantId) {
            history.push(generatePath(Routes.MenuOperations, { brandId, restaurantId }));
        }
    };

    const handleRowClick = React.useCallback(
        (cell: Cell<TableRow, TableCell<unknown>> | undefined, event?: React.MouseEvent<HTMLTableCellElement>) => {
            if (!cell) {
                return;
            }

            const { menuId: clickedMenuId } = cell.row.allCells[0].value.value as { menuId: number };
            if (clickedMenuId && cell.column.id !== 'action') {
                if (useNewEditMenuPages) {
                    const categoriesPagePath = generatePath(
                        `${path}/${clickedMenuId}${MenuRoutes.Products}`.replaceAll('//', '/'),
                        { brandId, restaurantId, menuId: clickedMenuId },
                    );
                    if (event?.ctrlKey) {
                        window.open(categoriesPagePath);
                    } else {
                        history.push(categoriesPagePath);
                    }
                    return;
                }
                const restaurantInUrl = restaurantId ? `/restaurants/${restaurantId}` : '';
                const url = new URL(
                    `/home/brands/${brandId}${restaurantInUrl}/menus/${clickedMenuId}/categories`,
                    ENV.BACKOFFICE_URL,
                );
                window.location.assign(url.href);
            }
        },
        [brandId, history, path, restaurantId, useNewEditMenuPages],
    );

    const headers = React.useMemo(
        () => [
            {
                Header: computeText(intl, 'name.name'),
                accessor: 'name',
                width: restaurantId ? '25%' : '100%',
            },
            ...(restaurantId
                ? [
                      {
                          Header: computeText(intl, 'status.status'),
                          accessor: 'isActive',
                          width: '10%',
                      },
                      {
                          Header: computeText(intl, 'menu.table.associatedChannels'),
                          accessor: 'channels',
                          width: '55%',
                      },
                  ]
                : []),
            {
                Header: computeText(intl, 'action.action'),
                accessor: 'action',
                width: '10%',
                disableSortBy: true,
            },
        ],
        [intl, restaurantId],
    );

    const rows: TableRow[] = React.useMemo(
        () =>
            [...(menus ?? [])].sort(compareIsActive).map((menu) => {
                const hasPosDevicesAssociated = menu.posDevices.length > 0;
                const hasKioskDevicesAssociated = menu.channels.find(
                    (channel) => channel.channelId === ChannelId.KIOSK,
                );

                const onClickHandler = (action: MenuTableActionTypes) => () => handleAction(action, menu);
                const rowDropdownActions = [
                    {
                        value: 1,
                        label: computeText(intl, 'action.edit'),
                        onClick: onClickHandler('showEditChannelModal'),
                        isActive: !!userAccessFlags?.MENU_EDIT,
                    },
                    {
                        value: 2,
                        label: computeText(intl, 'action.duplicate'),
                        onClick: onClickHandler('showDuplicateModal'),
                        isActive: !!userAccessFlags?.MENU_CREATE_OR_DELETE,
                    },
                    {
                        value: 3,
                        label: computeText(intl, 'action.copy'),
                        onClick: onClickHandler('showCopyModal'),
                        isActive: !!userAccessFlags?.MENU_CREATE_OR_DELETE,
                    },
                    {
                        value: 4,
                        label: computeText(intl, 'action.export'),
                        onClick: onClickHandler('exportMenu'),
                        isActive: true,
                    },
                    {
                        value: 5,
                        label: computeText(intl, 'action.delete'),
                        onClick: onClickHandler('showDeleteModal'),
                        isActive: !!userAccessFlags?.MENU_CREATE_OR_DELETE,
                    },
                    {
                        value: 6,
                        label: computeText(intl, 'action.publish'),
                        onClick: onClickHandler('showPublishModal'),
                        isActive: (hasPosDevicesAssociated || hasKioskDevicesAssociated) && restaurantId,
                    },
                ];

                return {
                    name: {
                        type: 'string',
                        value: {
                            text: menu.name,
                            menuId: menu.menuId,
                        },
                    },
                    isActive: {
                        type: 'badge',
                        value: menu.isActive
                            ? {
                                  backgroundColor: theme.color.emerald,
                                  text: computeText(intl, 'status.active'),
                              }
                            : {
                                  backgroundColor: theme.color.bloodyMary,
                                  text: computeText(intl, 'status.inactive'),
                              },
                    },
                    channels: {
                        type: 'list',
                        value: {
                            listItems: [
                                ...menu.channels.map((channel) => ({
                                    icon: getChannelIcon(channel.channelId),
                                    text: getChannelOrDeviceText(intl, channel),
                                })),
                                ...menu.posDevices.map(({ deviceName }) => ({
                                    icon: getChannelIcon(ChannelId.POS),
                                    text: deviceName,
                                })),
                            ],
                        },
                    },
                    action: {
                        type: 'dropdownText',
                        value: {
                            button: <SVGIcon icon={SVGIcons.MORE_VERTICAL} size={16} />,
                            align: 'end',
                            withPortal: true,
                            items: rowDropdownActions.filter(({ isActive }) => isActive),
                            type: 'text',
                        },
                    },
                };
            }),
        [menus, intl, handleAction, restaurantId],
    );

    useEffect(() => {
        if (name && type === 'exportMenu' && menuExport != null) {
            const fileToSave = new Blob([JSON.stringify(menuExport)], {
                type: 'application/json',
            });
            const today = new Date().toLocaleDateString();
            const parsedMenuName = name.toLocaleLowerCase().replace(' ', '-');
            saveAs(fileToSave, `export-${parsedMenuName}-${today}`);
            setAction(defaultMenuAction);
        }
    }, [name, type, menuExport, setAction]);

    useEffect(() => {
        if (menuId != null && type === 'exportMenu') {
            getMenuExportById({ menuId, token });
        }
    }, [menuId, token, type, getMenuExportById]);

    return {
        headers,
        rows,
        action,
        brandId,
        restaurantId,
        isGetMenuLoading,
        isExportMenuLoading,
        handleRowClick,
        toggleCreateModal,
        toggleAIMenuScanModal,
        goToImportPage,
        clearAction: () => setAction(defaultMenuAction),
        userAccessFlags,
    };
}
