import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Cell, Column } from 'react-table';
import { computeCurrency, computeText } from '@/locales/utils';
import type { TableCell, TableRow } from '@/components/atoms/Table/Table.type';
import { useHistory, useRouteMatch } from 'react-router';
import { theme } from '@/core/theme';
import { redirectToBackofficeV1 } from '@/utils/backoffice-v1';
import { fetchDataProps, DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX } from '@/components/atoms/Table';
import { useGetCustomerListMutation } from '@/services/innovorder/customer/customer.endpoints';
import { getParamFromQueryString, replaceParamInUrl } from '@/utils/history';
import { getUserRestaurants } from '@/redux/user';
import { useSelector } from 'react-redux';
import { UserRestaurant } from '@/services/innovorder/user/user.type';
import { CustomerFetchOptions } from '@/services/innovorder/customer/customer.types';
import { convertTableSortToQuerySort } from './utils';

export type CustomerTableViewModel = {
    brandId: number;
    headers: Column<TableRow>[];
    rows: TableRow[];
    isLoading: boolean;
    handleRowClick: (
        cell: Cell<TableRow, TableCell<unknown>> | undefined,
        event?: React.MouseEvent<HTMLTableCellElement>,
    ) => void;
    fetchData: ({ pageIndex, pageSize }: fetchDataProps) => void;
    fetchDataTotalCount: number;
    buttonLabel: string;
    selectedRestaurantId: number;
    restaurants: UserRestaurant[] | undefined;
    handleRestaurantChange: (restaurantId: number) => void;
    defaultHiddenColumns: string[];
    showOptionsModal: boolean;
    handleOpenOptionModal: () => void;
    handleCloseOptionModal: () => void;
    customerFetchOptions: CustomerFetchOptions;
    handleCustomerFetchOptionsChange: (options: Partial<CustomerFetchOptions>) => void;
};

export function useCustomersTableVM(): CustomerTableViewModel {
    const intl = useIntl();
    const { params } = useRouteMatch<{ brandId: string }>();
    const brandId = Number.parseInt(params.brandId, 10);
    const restaurants = useSelector(getUserRestaurants);
    const history = useHistory();

    const [customerFetchOptions, setCustomerFetchOptions] = useState<CustomerFetchOptions>({
        getArchivedCustomers: false,
        getDisabledCustomers: false,
        emailDomainNames: null,
        groups: null,
    });

    const [selectedRestaurantId, setSelectedRestaurantId] = useState(
        Number(getParamFromQueryString(history.location.search, 'restaurant')) || 0,
    );
    const [showOptionsModal, setShowOptionsModal] = useState<boolean>(false);

    const [fetchCustomerList, { data, isLoading }] = useGetCustomerListMutation();

    const { count = 0, customers: customerList } = data || {};

    const dateFormatter = Intl.DateTimeFormat(intl.locale, { dateStyle: 'short', timeStyle: 'short' });

    const buttonLabel =
        restaurants && selectedRestaurantId
            ? restaurants.find((restaurant) => restaurant.restaurantId === selectedRestaurantId)?.name || '???'
            : intl.formatMessage({ id: 'orderList.filters.allRestaurants' });

    const fetchData = useCallback(
        ({ pageIndex, pageSize, globalFilter, sortBy }: fetchDataProps) => {
            fetchCustomerList({
                brandId,
                ...customerFetchOptions,
                selectedRestaurantId,
                search: globalFilter,
                orderBy: convertTableSortToQuerySort(sortBy),
                limit: pageSize ?? DEFAULT_PAGE_SIZE,
                offset: pageIndex === undefined || pageSize === undefined ? DEFAULT_PAGE_INDEX : pageIndex * pageSize,
            });
        },
        [brandId, customerFetchOptions, selectedRestaurantId, fetchCustomerList],
    );

    const handleRowClick = React.useCallback(
        (cell: Cell<TableRow, TableCell<unknown>> | undefined, event?: React.MouseEvent<HTMLTableCellElement>) => {
            redirectToBackofficeV1(`/home/customers/${cell?.row.values.id.value.text}/info`, event?.ctrlKey);
        },
        [],
    );

    const handleRestaurantChange = useCallback(
        (restaurantId: number) => {
            setSelectedRestaurantId(restaurantId);
            replaceParamInUrl(history, 'restaurant', String(restaurantId));
        },
        [history],
    );

    const headers = React.useMemo(
        () => [
            {
                Header: computeText(intl, 'customers.id'),
                accessor: 'id',
            },
            {
                Header: computeText(intl, 'customers.name'),
                accessor: 'name',
            },
            {
                Header: computeText(intl, 'customers.email'),
                accessor: 'email',
            },
            {
                Header: computeText(intl, 'customers.phone'),
                accessor: 'phone',
            },
            {
                Header: computeText(intl, 'customers.totalSpentAmount'),
                accessor: 'totalSpentAmount',
            },
            {
                Header: computeText(intl, 'customers.orderCount'),
                accessor: 'orderCount',
            },
            {
                Header: computeText(intl, 'customers.averageCart'),
                accessor: 'averageCart',
            },
            {
                Header: computeText(intl, 'customers.lastOrder'),
                accessor: 'lastOrder',
            },
            {
                Header: computeText(intl, 'customers.frequency'),
                accessor: 'frequency',
            },
            {
                Header: computeText(intl, 'customers.status'),
                accessor: 'status',
            },
            {
                Header: computeText(intl, 'customers.ewalletBalance'),
                accessor: 'ewalletBalance',
            },
            {
                Header: computeText(intl, 'customers.archivedAt'),
                accessor: 'archivedAt',
            },
            {
                Header: computeText(intl, 'customers.disabledAt'),
                accessor: 'disabledAt',
            },
        ],
        [intl],
    );

    const rows: TableRow[] = React.useMemo(
        () =>
            (customerList ?? []).map((customer) => {
                return {
                    id: {
                        type: 'string',
                        value: {
                            text: customer.customerId,
                        },
                    },
                    name: {
                        type: 'string',
                        value: {
                            text: `${customer.firstName} ${customer.lastName}`,
                        },
                    },
                    email: {
                        type: 'string',
                        value: {
                            text: customer.email,
                        },
                    },
                    phone: {
                        type: 'string',
                        value: {
                            text: customer.phone,
                        },
                    },
                    totalSpentAmount: {
                        type: 'string',
                        value: {
                            text: computeCurrency({ intl, amount: customer.totalSpent, currency: 'EUR' }),
                        },
                    },
                    orderCount: {
                        type: 'string',
                        value: {
                            text: customer.totalOrders,
                        },
                    },
                    averageCart: {
                        type: 'string',
                        value: {
                            text: computeCurrency({ intl, amount: customer.averagePurchase, currency: 'EUR' }),
                        },
                    },
                    lastOrder: {
                        type: 'string',
                        value: {
                            text: dateFormatter.format(new Date(customer.lastOrder)),
                        },
                    },
                    frequency: {
                        type: 'string',
                        value: {
                            text: customer.averageOrderPerWeek,
                        },
                    },
                    status: {
                        type: 'badge',
                        value:
                            customer.disabledAt || customer.archivedAt
                                ? {
                                      backgroundColor: theme.color.bloodyMary,
                                      text: computeText(intl, 'status.inactive'),
                                  }
                                : {
                                      backgroundColor: theme.color.emerald,
                                      text: computeText(intl, 'status.active'),
                                  },
                    },
                    ewalletBalance: {
                        type: 'string',
                        value: {
                            text: computeCurrency({ intl, amount: customer.balance, currency: 'EUR' }),
                        },
                    },
                    archivedAt: {
                        type: 'string',
                        value: {
                            text: customer.archivedAt && dateFormatter.format(new Date(customer.archivedAt)),
                        },
                    },
                    disabledAt: {
                        type: 'string',
                        value: {
                            text: customer.disabledAt && dateFormatter.format(new Date(customer.disabledAt)),
                        },
                    },
                };
            }),
        [intl, dateFormatter, customerList],
    );

    const handleOpenOptionModal = useCallback(() => {
        setShowOptionsModal(true);
    }, []);

    const handleCloseOptionModal = useCallback(() => {
        setShowOptionsModal(false);
    }, []);

    const handleCustomerFetchOptionsChange = useCallback(
        (options: Partial<CustomerFetchOptions>) => {
            setCustomerFetchOptions({ ...customerFetchOptions, ...options });
        },
        [customerFetchOptions],
    );

    return {
        brandId,
        headers,
        rows,
        isLoading,
        handleRowClick,
        fetchData,
        fetchDataTotalCount: count,
        buttonLabel,
        selectedRestaurantId,
        restaurants,
        handleRestaurantChange,
        defaultHiddenColumns: ['archivedAt', 'disabledAt'],
        showOptionsModal,
        handleOpenOptionModal,
        handleCloseOptionModal,
        customerFetchOptions,
        handleCustomerFetchOptionsChange,
    };
}
