/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Column, Filters, SortingRule } from 'react-table';
import { IntlShape } from 'react-intl';
import { Theme } from '@emotion/react';
import { computeCurrency, computeText } from '@/locales/utils';
import { assertUnreachable } from '@/utils/typescript';
import { formatDate } from '@/utils/date';
import { getChannelIcon } from '@/components/atoms/Icon/utils';
import { TableRow } from '@/components/atoms/Table/Table.type';
import { filterTypes } from '@/components/atoms/Table/Table.utils';
import type {
    GetOrderFilters,
    GetOrdersSort,
    OmnichannelOrder,
    OrderStatus,
    WebPaymentStatus,
} from '@/services/innovorder/orders/order.type';
import { BrandType } from '@/services/innovorder/brand/brand.type';
import { UserRestaurant, UserRoles } from '@/services/innovorder/user/user.type';

const defaultHiddenColumns = {
    [BrandType.MassCatering]: [
        'turnoverWithVat',
        'phoneNumber',
        'orderId',
        'consumptionMode',
        'orderDate',
        'expectedDate',
        'paymentAmount',
    ],
    [BrandType.CommercialCatering]: ['turnoverWithVat', 'badgeNumber', 'pricingRule'],
};
export const getDefaultHiddenColumns = (brandType: BrandType): string[] => defaultHiddenColumns[brandType];

const getStatusColor = (status: OrderStatus, theme: Theme): string => {
    if (status === 'PAID') return theme.color.emerald;
    if (status === 'VALIDATED') return theme.color.mint;
    if (status === 'CANCELLED') return theme.color.bloodyMary;
    return assertUnreachable(status);
};

const getWebPaymentStatusColor = (theme: Theme, webPaymentStatus?: WebPaymentStatus): string => {
    switch (webPaymentStatus) {
        case 'PAYMENT_OK':
            return theme.color.emerald;
        case 'PAYMENT_PENDING':
            return theme.color.mint;
        case 'PAYMENT_REFUNDED':
            return theme.color.orange100;
        case 'PAYMENT_REFUSED':
            return theme.color.bloodyMary;
        default:
            return theme.color.slate300;
    }
};

export const getOrderPricingRuleCode = (order: OmnichannelOrder): string => {
    if (order.pricingRule?.code) {
        return order.pricingRule.code;
    }

    if (order.customer?.student?.pricingRuleCode) {
        return order.customer.student.pricingRuleCode;
    }

    return '';
};

export const convertTableFiltersToQueryFilters = (columnFilters?: Filters<TableRow>): GetOrderFilters => {
    if (!columnFilters?.length) return {};
    const filters = columnFilters.reduce((acc: GetOrderFilters, column) => {
        const value = Array.isArray(column.value) ? column.value.join() : column.value;
        switch (column.id) {
            case 'status':
            case 'customerName':
            case 'badgeNumber':
            case 'pricingRule':
            case 'ticketNumber':
            case 'restaurantName':
            case 'phoneNumber':
                acc[column.id] = value;
                return acc;
            case 'channel':
                acc.channelIds = value;
                return acc;
            case 'consumptionMode':
                acc.consumptionModeIds = value;
                return acc;
            case 'totalWithVat': {
                const { from, to } = value || {};
                acc.totalPriceMin = from ? from * 100 : from;
                acc.totalPriceMax = to ? to * 100 : to;
                return acc;
            }
            case 'turnoverWithVat': {
                const { from, to } = value || {};
                acc.turnoverMin = from ? from * 100 : from;
                acc.turnoverMax = to ? to * 100 : to;
                return acc;
            }
            case 'orderDate': {
                const { startDate, endDate } = value || {};
                acc.startDate = startDate;
                acc.endDate = endDate ?? Date.now();
                return acc;
            }
            default:
                throw new Error(`Filter column not managed: ${column.id}`);
        }
    }, {});
    return filters;
};

export const convertTableSortToQuerySort = (sortBy?: SortingRule<TableRow>[]): GetOrdersSort[] => {
    if (!sortBy?.length) return [];

    const sortOrder = sortBy
        .map((sort) => {
            if (sort.id === 'orderDate') return sort.desc ? '-startDate' : 'startDate';
            if (sort.id === 'status') return sort.desc ? '-status' : 'status';
            if (sort.id === 'ticketDate') return sort.desc ? '-lastUpdateDate' : 'lastUpdateDate';
            if (sort.id === 'ticketNumber') return sort.desc ? '-ticketNumber' : 'ticketNumber';
            if (sort.id === 'channel') return sort.desc ? '-channelId' : 'channelId';
            if (sort.id === 'orderId') return sort.desc ? '-omnichannelOrderId' : 'omnichannelOrderId';
            if (sort.id === 'consumptionMode') return sort.desc ? '-consumptionMode' : 'consumptionMode';
            if (sort.id === 'customerName') return sort.desc ? '-customerName' : 'customerName';
            if (sort.id === 'badgeNumber') return sort.desc ? '-badgeNumber' : 'badgeNumber';
            if (sort.id === 'pricingRule') return sort.desc ? '-pricingRule' : 'pricingRule';
            if (sort.id === 'phoneNumber') return sort.desc ? '-phoneNumber' : 'phoneNumber';
            if (sort.id === 'restaurantName') return sort.desc ? '-restaurantName' : 'restaurantName';
            if (sort.id === 'turnoverWithVat') return sort.desc ? '-turnover' : 'turnover';
            if (sort.id === 'totalWithVat')
                return sort.desc ? '-totalPriceDiscountedWithTaxIncluded' : 'totalPriceDiscountedWithTaxIncluded';
            return false;
        })
        .filter((sort) => sort) as GetOrdersSort[];
    return sortOrder;
};

export const getOrdersTableRowsVM = (
    orders: OmnichannelOrder[] | undefined,
    intl: IntlShape,
    theme: Theme,
): TableRow[] =>
    !orders
        ? []
        : orders.map((order) => {
              const paymentAmount = order.payments.reduce((acc, current) => acc + current.value, 0);
              const paymentMethod = Array.from(
                  new Set(order.payments.map(({ type }) => intl.formatMessage({ id: `order.paymentMethod.${type}` }))),
              );
              let customerName = '';
              if (order.customer) {
                  customerName = `${order.customer?.firstName} ${order.customer?.lastName}`;
              } else if (order.userName) {
                  customerName = order.userName;
              }

              return {
                  ticketNumber: {
                      type: 'string',
                      value: { text: order.ticketNumber || String(order.dailyOrderId) },
                  },
                  status: {
                      type: 'badge',
                      value: {
                          text: `order.status.${order.status}`,
                          backgroundColor: getStatusColor(order.status, theme),
                      },
                  },
                  restaurantName: {
                      type: 'string',
                      value: { text: order.restaurant?.name || '' },
                  },
                  channel: {
                      type: 'texticon',
                      value: {
                          text: `order.channel.${order.channelId}`,
                          icon: getChannelIcon(order.channelId),
                      },
                  },
                  customerName: {
                      type: 'string',
                      value: { text: customerName },
                  },
                  badgeNumber: {
                      type: 'string',
                      value: { text: order.customer?.badgeNumber || '' },
                  },
                  pricingRule: {
                      type: 'string',
                      value: { text: getOrderPricingRuleCode(order) },
                  },
                  phoneNumber: {
                      type: 'string',
                      value: { text: order.customer?.phone || '' },
                  },
                  orderId: {
                      type: 'string',
                      value: {
                          text: order.omnichannelOrderId || '',
                          isTruncated: true,
                          title: order.omnichannelOrderId || '',
                      },
                  },
                  consumptionMode: {
                      type: 'string',
                      value: { text: `order.consumptionMode.${order.consumptionModeId}` },
                  },
                  orderDate: {
                      type: 'string',
                      value: { text: formatDate(order.startDate, 'DD/MM/YYYY HH:mm') },
                  },
                  expectedDate: {
                      type: 'string',
                      value: { text: formatDate(order.expectedDate, 'DD/MM/YYYY HH:mm') },
                  },
                  ticketDate: {
                      type: 'string',
                      value: { text: formatDate(order.lastUpdateDate, 'DD/MM/YYYY HH:mm') },
                  },
                  totalWithVat: {
                      type: 'string',
                      value: { text: computeCurrency({ intl, amount: order.totalPriceDiscountedWithTaxIncluded }) },
                  },
                  turnoverWithVat: {
                      type: 'string',
                      value: { text: computeCurrency({ intl, amount: order.turnover }) },
                  },
                  paymentMethod: {
                      type: 'list',
                      value: {
                          listItems: paymentMethod,
                          isTruncated: true,
                      },
                  },
                  paymentStatus: {
                      type: 'badge',
                      value: {
                          backgroundColor: getWebPaymentStatusColor(theme, order.webPaymentStatus),
                          text: order.webPaymentStatus ? `order.webPaymentStatus.${order.webPaymentStatus}` : '',
                      },
                  },
                  paymentAmount: {
                      type: 'string',
                      value: { text: computeCurrency({ intl, amount: paymentAmount }) },
                  },

                  linkTo: {
                      type: 'linkTo',
                      value: { orderId: String(order.omnichannelOrderId) },
                  },
              };
          });

export const getOrdersTableColumnsVM = (intl: IntlShape, brandType?: BrandType): readonly Column<TableRow>[] => [
    {
        Header: computeText(intl, 'order.field.ticketNumber'),
        accessor: 'ticketNumber',
        minWidth: 100,
        disableSortBy: true,
    },
    {
        Header: computeText(intl, 'order.field.status'),
        accessor: 'status',
        minWidth: 100,
        filter: filterTypes.list,
        // @ts-ignore
        Filter: [
            { value: 'PAID', label: computeText(intl, 'order.status.PAID') },
            { value: 'CANCELLED', label: computeText(intl, 'order.status.CANCELLED') },
            { value: 'VALIDATED', label: computeText(intl, 'order.status.VALIDATED') },
        ].sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
        Header: computeText(intl, 'order.field.paymentStatus'),
        accessor: 'paymentStatus',
        disableSortBy: true,
        minWidth: 100,
        disableFilters: true,
    },
    {
        Header: computeText(intl, 'order.field.restaurant'),
        accessor: 'restaurantName',
        minWidth: 150,
    },
    {
        Header: computeText(intl, 'order.field.channel'),
        accessor: 'channel',
        minWidth: 100,
        filter: filterTypes.list,
        // @ts-ignore
        Filter: [
            { value: 1, label: computeText(intl, 'order.channel.1') },
            { value: 2, label: computeText(intl, 'order.channel.2') },
            { value: 5, label: computeText(intl, 'order.channel.5') },
            { value: 8, label: computeText(intl, 'order.channel.8') },
            { value: 9, label: computeText(intl, 'order.channel.9') },
            { value: 10, label: computeText(intl, 'order.channel.10') },
            { value: 11, label: computeText(intl, 'order.channel.11') },
        ].sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
        Header: computeText(
            intl,
            brandType === 'MASS_CATERING' ? 'order.field.customerNameMassCatering' : 'order.field.customerName',
        ),
        accessor: 'customerName',
        filter: filterTypes.string,
        minWidth: 180,
    },
    {
        Header: computeText(intl, 'order.field.badgeNumber'),
        accessor: 'badgeNumber',
        filter: filterTypes.string,
        minWidth: 100,
    },
    {
        Header: computeText(intl, 'order.field.pricingRuleInOrder'),
        accessor: 'pricingRule',
        filter: filterTypes.string,
        minWidth: 100,
    },
    {
        Header: computeText(intl, 'order.field.phone'),
        accessor: 'phoneNumber',
        minWidth: 100,
    },
    {
        Header: computeText(intl, 'order.field.orderId'),
        accessor: 'orderId',
        maxWidth: 150,
        minWidth: 130,
        disableFilters: true,
    },
    {
        Header: computeText(intl, 'order.field.consumptionMode'),
        accessor: 'consumptionMode',
        minWidth: 140,
        filter: filterTypes.list,
        // @ts-ignore
        Filter: [
            { value: 1, label: computeText(intl, 'order.consumptionMode.1') },
            { value: 2, label: computeText(intl, 'order.consumptionMode.2') },
            { value: 3, label: computeText(intl, 'order.consumptionMode.3') },
            { value: 4, label: computeText(intl, 'order.consumptionMode.4') },
        ].sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
        Header: computeText(intl, 'order.field.orderDate'),
        accessor: 'orderDate',
        minWidth: 160,
        filter: filterTypes.datePicker,
    },
    {
        Header: computeText(intl, 'order.field.expectedDate'),
        accessor: 'expectedDate',
        minWidth: 160,
        disableSortBy: true,
        disableFilters: true,
    },
    {
        Header: computeText(intl, 'order.field.ticketDate'),
        accessor: 'ticketDate',
        minWidth: 160,
        disableFilters: true,
    },
    {
        Header: computeText(intl, 'order.field.totalWithVat'),
        accessor: 'totalWithVat',
        minWidth: 100,
        filter: filterTypes.number,
    },
    {
        Header: computeText(intl, 'order.field.turnoverWithVat'),
        accessor: 'turnoverWithVat',
        minWidth: 100,
        filter: filterTypes.number,
    },
    {
        Header: computeText(intl, 'order.field.paymentMethod'),
        accessor: 'paymentMethod',
        disableSortBy: true,
        minWidth: 100,
        disableFilters: true,
    },
    {
        Header: computeText(intl, 'order.field.paymentAmount'),
        accessor: 'paymentAmount',
        disableSortBy: true,
        minWidth: 120,
        disableFilters: true,
    },
];

export function getRestaurantsCondition(
    selectedRestaurantId: number,
    userRole?: UserRoles,
    restaurants?: UserRestaurant[],
): number | number[] {
    if (userRole === UserRoles.restaurant && selectedRestaurantId === 0) {
        return restaurants?.map(({ restaurantId }) => restaurantId) ?? selectedRestaurantId;
    }
    return selectedRestaurantId;
}
