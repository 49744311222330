import { Input } from '@/components/atoms/Form/Input';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Select, { Option } from '@/components/atoms/Select';
import { Text } from '@/components/atoms/Text';
import { SavingBar } from '@/components/atoms/SavingBar';
import { getUseShift } from '@/redux/launchDarkly';
import { computeText } from '@/locales/utils';
import { computeAndTriggerTouchedFields } from '@/utils/form/utils';
import {
    BaseEntranceFeeAttributes,
    EntranceFeeType,
    EntranceFeePayload,
    EntranceFeeStrategy,
    VatEnum,
} from '@/services/innovorder/entranceFee/entranceFee.type';
import { getEntranceFee, updateEntranceFee } from '@/redux/grantAndEntranceFee';
import { EntranceFeeRestaurants } from './EntranceFeeRestaurants';
import {
    ParentContainer,
    Container,
    FlexContainer,
    TitleContainer,
    CustomTitle,
    FormContainer,
    MargedTitle,
    Flex,
    FormChildrens,
    WarningFormContainer,
    EntranceFeeRestaurantsContainer,
} from './EntranceFeeForm.style';
import EntranceFeeShifts from './EntranceFeeShifts/EntranceFeeShifts';

export const EntranceFeeStrategyTranslationMap = {
    [EntranceFeeStrategy.Always]: 'entranceFee.select.always',
    [EntranceFeeStrategy.Daily_minimum_granted]: 'entranceFee.select.daily',
};

export const entranceFeeStrategyMap: Option<EntranceFeeStrategy>[] = [
    { label: EntranceFeeStrategyTranslationMap[EntranceFeeStrategy.Always], value: EntranceFeeStrategy.Always },
    {
        label: EntranceFeeStrategyTranslationMap[EntranceFeeStrategy.Daily_minimum_granted],
        value: EntranceFeeStrategy.Daily_minimum_granted,
    },
];

export const entranceFeeCAMap: Option<EntranceFeeType>[] = [
    { label: 'entranceFee.select.salesExcluded', value: EntranceFeeType.RoyaltyFee },
    { label: 'entranceFee.select.salesIncluded', value: EntranceFeeType.AdmissionFee },
];

export const vatMap: Option<VatEnum>[] = [
    { label: '0 %', value: VatEnum.zero },
    { label: '2.1 %', value: VatEnum.twoOne },
    { label: '5.5 %', value: VatEnum.fiveFive },
    { label: '6 %', value: VatEnum.six },
    { label: '8.5 %', value: VatEnum.heigthFive },
    { label: '10 %', value: VatEnum.ten },
    { label: '20 %', value: VatEnum.twenty },
    { label: '21 %', value: VatEnum.twentyOne },
];

type EntranceFeeFormProps = {
    entranceFee?: EntranceFeePayload;
    error?: string;
    loading: boolean;
    updateSuccess?: boolean;
    onSubmit: () => void;
    isCreateMode?: boolean;
};

const EntranceFeeForm: React.FunctionComponent<React.PropsWithChildren<EntranceFeeFormProps>> = ({
    entranceFee,
    error: callError,
    loading,
    isCreateMode,
    onSubmit,
}) => {
    const intl = useIntl();
    const [modal, setModal] = useState<{ restaurantId: number; name: string } | null>(null);

    const dispatch = useDispatch();
    const useShift = useSelector(getUseShift);
    const entranceFeeFromState = useSelector(getEntranceFee);

    const {
        control,
        formState: { touchedFields, dirtyFields, isSubmitSuccessful, isValid },
        handleSubmit,
        watch,
        trigger,
        reset,
        getValues,
    } = useForm<BaseEntranceFeeAttributes>({
        defaultValues: {
            strategy: EntranceFeeStrategy.Always,
            vat: VatEnum.zero,
            type: EntranceFeeType.AdmissionFee,
            restaurants: [],
            ...entranceFee,
        },
    });

    const handleOnConfigure = ({ restaurantId, name }: { restaurantId: number; name: string }) => {
        isValid && setModal({ restaurantId, name });
    };

    const handleOnHide = () => {
        setModal(null);
    };

    const finalTouchedFields = computeAndTriggerTouchedFields<EntranceFeePayload, BaseEntranceFeeAttributes>(
        entranceFee,
        touchedFields,
        trigger,
    );

    const handleCancelChanges = (): void => {
        reset(
            isCreateMode
                ? {
                      strategy: EntranceFeeStrategy.Always,
                      vat: VatEnum.zero,
                      type: EntranceFeeType.AdmissionFee,
                      amount: 0,
                      code: '',
                      dailyLimit: 0,
                      labelTicket: '',
                  }
                : {
                      strategy: EntranceFeeStrategy.Always,
                      vat: VatEnum.zero,
                      type: EntranceFeeType.AdmissionFee,
                      ...entranceFee,
                  },
        );
    };

    useEffect(() => {
        const subscription = watch((value) => {
            const updatedValue = useShift ? { ...value, restaurants: entranceFeeFromState.restaurants } : { ...value };
            dispatch(updateEntranceFee(updatedValue));
        });

        return () => subscription.unsubscribe();
    }, [watch, dispatch, entranceFeeFromState, useShift]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset(getValues());
        }
    }, [isSubmitSuccessful, getValues, reset]);

    return (
        <ParentContainer>
            <Container>
                <form>
                    <FlexContainer>
                        <div style={{ flex: 1, paddingRight: 50 }}>
                            <TitleContainer>
                                <CustomTitle text={entranceFee ? 'entranceFee.title' : 'entranceFee.create'} />
                                {entranceFee && <CustomTitle text={entranceFee.code} />}
                            </TitleContainer>
                            <Text text="entranceFee.description" />
                        </div>
                        <FormContainer data-testid="entranceFee-form">
                            <MargedTitle text="entranceFee.default" type="small" weight="medium" />
                            <Flex>
                                <FormChildrens style={{ marginRight: 20 }}>
                                    <Controller
                                        control={control}
                                        name="code"
                                        rules={{
                                            required: computeText(intl, 'field.required'),
                                        }}
                                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                            <Input
                                                error={error?.message}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                placeholder="entranceFee.code"
                                                labelId="entranceFee.code"
                                                isTouched={finalTouchedFields.code}
                                            />
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name="amount"
                                        rules={{
                                            required: computeText(intl, 'field.required'),
                                        }}
                                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                            <Input
                                                error={
                                                    error?.message ||
                                                    (error?.type && computeText(intl, 'field.greaterThanZero'))
                                                }
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value?.toString()}
                                                placeholder="entranceFee.amount"
                                                type="number"
                                                unitType="currency"
                                                labelId="entranceFee.amount"
                                                isTouched={finalTouchedFields.amount}
                                                min={0}
                                                max={999999999}
                                            />
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name="strategy"
                                        rules={{ required: computeText(intl, 'field.required') }}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Select<EntranceFeeStrategy>
                                                value={value}
                                                options={entranceFeeStrategyMap}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                labelId="entranceFee.strategy"
                                                isTouched={finalTouchedFields.strategy}
                                            />
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name="type"
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Select<EntranceFeeType>
                                                value={value ?? EntranceFeeType.AdmissionFee}
                                                options={entranceFeeCAMap}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                labelId="entranceFee.type"
                                                isTouched={value ? finalTouchedFields.type : undefined}
                                            />
                                        )}
                                    />
                                </FormChildrens>
                                <FormChildrens>
                                    <Controller
                                        control={control}
                                        name="labelTicket"
                                        rules={{ required: computeText(intl, 'field.required') }}
                                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                            <Input
                                                error={error?.message}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                placeholder="entranceFee.labelTicket"
                                                labelId="entranceFee.labelTicket"
                                                isTouched={finalTouchedFields.labelTicket}
                                            />
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name="dailyLimit"
                                        rules={{ required: computeText(intl, 'field.required'), min: '1' }}
                                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                            <Input
                                                error={
                                                    error?.message ||
                                                    (error?.type && computeText(intl, 'field.greaterThanZero'))
                                                }
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value?.toString()}
                                                placeholder="entranceFee.dailyLimit"
                                                type="number"
                                                labelId="entranceFee.dailyLimit"
                                                isTouched={finalTouchedFields.dailyLimit}
                                                min={0}
                                                max={999999999}
                                            />
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name="vat"
                                        rules={{ required: computeText(intl, 'field.required') }}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Select<VatEnum>
                                                value={value}
                                                options={vatMap}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                labelId="entranceFee.vat"
                                                isTouched={finalTouchedFields.vat !== undefined}
                                            />
                                        )}
                                    />
                                </FormChildrens>
                            </Flex>
                        </FormContainer>
                    </FlexContainer>

                    {modal && (
                        <EntranceFeeShifts
                            restaurantId={modal.restaurantId}
                            name={modal.name}
                            handleOnHide={handleOnHide}
                            isCreateMode={!!isCreateMode}
                        />
                    )}
                </form>

                <CustomTitle text="entranceFee.customUse" />
                <Text text="entranceFee.descCustomUse" />
                {useShift && !isValid && (
                    <WarningFormContainer>
                        <Text text="entranceFee.warningForm" />
                    </WarningFormContainer>
                )}
                <div style={{ marginTop: 20 }}>
                    <EntranceFeeRestaurantsContainer useShift={useShift} isValid={isValid}>
                        <EntranceFeeRestaurants isCreateMode={isCreateMode} handleOnConfigure={handleOnConfigure} />
                    </EntranceFeeRestaurantsContainer>
                </div>
            </Container>
            <SavingBar
                onSave={handleSubmit(onSubmit)}
                error={callError}
                loading={loading}
                changesCount={Object.keys(dirtyFields).length}
                onCancel={handleCancelChanges}
            />
        </ParentContainer>
    );
};

export default EntranceFeeForm;
