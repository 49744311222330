import React, { useCallback, KeyboardEvent } from 'react';
import ReactTooltip from 'react-tooltip';
import { useTheme } from '@emotion/react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';
import { Label } from '@/components/atoms/Label';
import { computeText } from '@/locales/utils';
import { Container, IconContainer, StyledButton, Unit, UnstyledInput } from './Input.style';

export type InputUnitType = 'currency' | 'percentage';

export type InputSuggestions = {
    id: string;
    options: string[];
};

export type InputProps = {
    value?: string | number;
    placeholder?: string;
    error?: string;
    name?: string;
    type?: string;
    style?: React.CSSProperties;
    onAdd?: () => void;
    labelId?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDownCapture?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    unitType?: InputUnitType;
    disabled?: boolean;
    required?: boolean;
    isTouched?: boolean;
    isDirty?: boolean;
    min?: string | number;
    max?: string | number;
    step?: string | number;
    className?: string;
    suggestions?: InputSuggestions;
    readOnly?: boolean;
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            value,
            error,
            name = '',
            onChange,
            onBlur,
            onKeyDownCapture,
            placeholder = '',
            type = 'text',
            onAdd,
            style,
            labelId,
            unitType,
            disabled,
            required,
            isTouched,
            isDirty,
            min,
            max,
            step,
            className,
            suggestions,
            readOnly,
            ...rest
        },
        ref,
    ) => {
        const intl = useIntl();
        const theme = useTheme();

        const handleKeyPress = useCallback(
            (event: KeyboardEvent) => {
                if (event.key === 'Enter' && onAdd) {
                    onAdd();
                }
            },
            [onAdd],
        );

        return (
            <div style={{ opacity: disabled ? 0.4 : 1, ...style }}>
                {labelId && <Label labelId={labelId} inputId={labelId} required={required} />}
                <Container
                    error={error}
                    isTouched={isTouched && isDirty !== false}
                    data-testid="input-container"
                    className={className}
                    style={{ opacity: disabled || readOnly ? 0.4 : 1 }}
                >
                    <UnstyledInput
                        ref={ref}
                        type={type}
                        value={value}
                        placeholder={computeText(intl, placeholder)}
                        onChange={onChange}
                        onKeyDownCapture={onKeyDownCapture}
                        onBlur={onBlur}
                        name={name}
                        id={labelId}
                        disabled={disabled}
                        min={min}
                        max={max}
                        step={step}
                        onKeyPress={handleKeyPress}
                        list={suggestions && suggestions.id}
                        autoComplete={suggestions ? 'off' : ''}
                        readOnly={readOnly}
                        {...rest}
                    />
                    {suggestions && (
                        <datalist id={suggestions.id} data-testid="suggestions-datalist">
                            {suggestions.options.map((option, index) => (
                                <option value={option} key={index} />
                            ))}
                        </datalist>
                    )}
                    {onAdd && (
                        <StyledButton onClick={onAdd} buttonType="primary">
                            <FormattedMessage id="button.add" />
                        </StyledButton>
                    )}
                    {unitType === 'currency' && <Unit>€</Unit>}
                    {unitType === 'percentage' && <Unit>%</Unit>}
                    {error && (
                        <IconContainer>
                            <div data-tip data-for="error">
                                <SVGIcon icon={SVGIcons.DANGER} size={14} color={theme.color.bloodyMary} />
                            </div>
                            <ReactTooltip id="error" place="top" effect="solid">
                                <span>{error}</span>
                            </ReactTooltip>
                        </IconContainer>
                    )}
                    {!error && !unitType && isTouched && isDirty !== false && (
                        <IconContainer>
                            <div data-tip data-for="touched">
                                <SVGIcon icon={SVGIcons.CHECK} size={14} color={theme.color.emerald} />
                            </div>
                        </IconContainer>
                    )}
                </Container>
            </div>
        );
    },
);
Input.displayName = 'Input';
