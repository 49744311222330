import React, { useMemo } from 'react';
import { TableColumn, TableRow } from '@/components/atoms/Table/Table.type';
import { computeText } from '@/locales/utils';
import { BadgeReader, DriverConfig } from '@/services/innovorder/badgeReader/badgeReader.type';
import { useIntl } from 'react-intl';
import { CellProps, Column } from 'react-table';
import { ReadingConditions } from './ReadingConditions';
import { DriverConfigs } from './DriverConfigs';
import { SVGIcon, SVGIcons } from '@/components/atoms/Icon/SVGIcon';

interface BadgeReadersListViewModel {
    rows: TableRow[];
    columns: TableColumn[];
}

interface BadgeReadersListViewModelProps {
    data: BadgeReader[];
    onEdit: (data: BadgeReader) => void;
    onDelete: (data: number) => void;
}

export const useBadgeReadersListVM = ({
    data,
    onEdit,
    onDelete,
}: BadgeReadersListViewModelProps): BadgeReadersListViewModel => {
    const intl = useIntl();

    const rows: TableRow[] = useMemo(
        () =>
            (data || []).map((badgeReader) => ({
                name: {
                    key: badgeReader.name,
                    type: 'string',
                    value: {
                        text: badgeReader.name,
                    },
                },
                deviceUuid: {
                    type: 'string',
                    value: {
                        text: badgeReader.device_uuid,
                    },
                },
                driver: {
                    type: 'string',
                    value: {
                        text: computeText(intl, `badgeReader.driver.${badgeReader.driver}`),
                    },
                },
                driverConfigs: {
                    type: 'string',
                    value: {
                        text: JSON.stringify(badgeReader.driverConfig),
                    },
                },
                readingConditions: {
                    type: 'string',
                    value: {
                        text: JSON.stringify(badgeReader.readingConditions),
                    },
                },
                actions: {
                    type: 'dropdownText',
                    value: {
                        button: <SVGIcon icon={SVGIcons.MORE_VERTICAL} size={16} />,
                        align: 'end',
                        withPortal: true,
                        items: [
                            {
                                value: 1,
                                label: computeText(intl, 'action.edit'),
                                onClick: () => onEdit(badgeReader),
                            },
                            {
                                value: 2,
                                label: computeText(intl, 'action.delete'),
                                onClick: () => onDelete(badgeReader.badgeReaderId),
                            },
                        ],
                        type: 'text',
                    },
                    linkTo: {
                        type: 'linkTo',
                        value: { badgeReaderId: String(badgeReader.badgeReaderId) },
                    },
                },
            })),
        [data],
    );

    const columns: Column<TableRow>[] = useMemo(
        () => [
            {
                Header: computeText(intl, 'badgeReader.table.name'),
                accessor: 'name',
                width: '15%',
            },
            {
                Header: computeText(intl, 'badgeReader.table.deviceUuid'),
                accessor: 'deviceUuid',
                width: '15%',
            },
            {
                Header: computeText(intl, 'badgeReader.table.driver'),
                accessor: 'driver',
                disableSortBy: true,
                width: '15%',
            },
            {
                Header: computeText(intl, 'badgeReader.table.driverConfigs'),
                accessor: 'driverConfigs',
                disableFilters: true,
                disableSortBy: true,
                Cell: ({ value }: CellProps<any>) => (
                    <DriverConfigs data={JSON.parse(value.value.text) as DriverConfig} />
                ),
                width: '20%',
            },
            {
                Header: computeText(intl, 'badgeReader.table.readingConditions'),
                accessor: 'readingConditions',
                disableFilters: true,
                disableSortBy: true,
                Cell: ({ value }: CellProps<any>) => (
                    <ReadingConditions data={value.value.text ? JSON.parse(value.value.text) : []} />
                ),
                width: '40%',
            },
            {
                Header: computeText(intl, 'badgeReader.table.readingConditions'),
                accessor: 'actions',
                disableFilters: true,
                disableSortBy: true,
                width: '10%',
            },
        ],
        [],
    );

    return {
        rows,
        columns,
    };
};
