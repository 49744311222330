import { routes } from '@/core/router/routes';
import { HELP_LINK } from '@/utils/constants';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserBrandId, getUserToken } from '@/redux/user';
import {
    useDeleteBadgeReaderMutation,
    useGetBadgeReaderQuery,
} from '@/services/innovorder/badgeReader/badgeReader.endpoints';
import { computeText } from '@/locales/utils';
import { useIntl } from 'react-intl';
import { confirm } from '@/core/asyncActions/confirm';
import { BadgeReader } from '@/services/innovorder/badgeReader/badgeReader.type';

export const useBadgeReaderVM = () => {
    const intl = useIntl();

    const [formProps, setFormProps] = useState<{ isOpen: boolean; data?: BadgeReader }>({ isOpen: false });
    const tabRoutes = useMemo(() => routes.filter(({ tab }) => tab === 'brands'), []);

    const token = useSelector(getUserToken);
    const brandId = useSelector(getUserBrandId) as number;

    const { data, isLoading } = useGetBadgeReaderQuery({ brandId, token }, { refetchOnMountOrArgChange: true });
    const [deleteBadgeReader] = useDeleteBadgeReaderMutation();

    const onHelp = () => {
        window.open(HELP_LINK);
    };

    const onCreate = () => {
        setFormProps({ isOpen: true });
    };

    const onEdit = (badgeReader: BadgeReader) => {
        setFormProps({ isOpen: true, data: badgeReader });
    };

    const onDelete = async (badgeReaderId: number) => {
        const hasConfirmed = await confirm(
            computeText(intl, 'badgeReader.delete.confirmation'),
            'badgeReader.delete.warning',
        );
        if (hasConfirmed) {
            deleteBadgeReader({ brandId, badgeReaderId });
        }
    };

    const hideForm = () => {
        setFormProps({ isOpen: false });
    };

    return {
        data,
        isLoading,
        formProps,
        tabRoutes,
        hideForm,
        onHelp,
        onCreate,
        onEdit,
        onDelete,
    };
};
